import * as Sentry from "@sentry/react";

export const telemetry = {
    setAuthenticatedUserContext: (userId: string) => {
        Sentry.setUser({ id: userId });
    },

    clearAuthenticatedUserContext: () => {
        Sentry.setUser(null);
    },

    trackInfo: (message: string, extra?: Record<string, any>) => {
        Sentry.captureMessage(message, {
            level: "info",
            extra: extra,
        });
    },

    trackError: (error: Error, extra?: Record<string, any>) => {
        Sentry.captureException(error, {
            level: "error",
            extra,
        });
    },
};
