import React from "react";
import styled from "styled-components";
import assets from "../common/assets";
import colors from "../common/colors";
import extractValue from "../common/helpers/extractValue";
import { space } from "../common/variables";

interface Props {
    bleedHorizontal?: "small" | "medium" | "large" | "xLarge";
    bleedTop?: "small" | "medium" | "large" | "xLarge";
    type: "danger" | "success";
    style?: React.CSSProperties;
    children?: React.ReactNode;
}

export const Alert = (props: Props) => {
    return (
        <Wrapper
            type={props.type}
            bleedHorizontal={props.bleedHorizontal}
            bleedTop={props.bleedTop}
            style={props.style}>
            <Content>
                <Icon>
                    {props.type === "danger"
                        ? assets.exclamation
                        : assets.checkmark}
                </Icon>
                <span>{props.children}</span>
            </Content>
        </Wrapper>
    );
};
const Content = styled.div`
    display: flex;
    align-items: start;
`;

const Icon = styled.div`
    min-height: 20px;
    max-height: 20px;
    min-width: 20px;
    max-width: 20px;
    display: inline-block;
    margin-right: 10px;
    margin-top: -1px;
`;

const Wrapper = styled.div<{
    bleedHorizontal?: "small" | "medium" | "large" | "xLarge";
    bleedTop?: "small" | "medium" | "large" | "xLarge";
    type: "danger" | "success";
}>`
    min-height: 50px;
    width: ${({ bleedHorizontal }) =>
        bleedHorizontal
            ? `calc(100% + ${2 * extractValue(space[bleedHorizontal]) + "px"})`
            : "100%"};
    margin-left: ${({ bleedHorizontal }) =>
        bleedHorizontal ? `-${space[bleedHorizontal]}` : "0"};
    margin-top: ${({ bleedTop }) => (bleedTop ? `-${space[bleedTop]}` : "0")};
    background: ${({ type }) =>
        type === "danger" ? colors.pink : colors.greenLight};
    display: flex;
    justify-content: center;
    align-items: center;
    padding: ${space.xSmall};
`;
