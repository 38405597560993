import { TooltipProvider } from "@radix-ui/react-tooltip";
import * as Sentry from "@sentry/react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import React from "react";
import { RouterProvider } from "react-router-dom";
import AuthProvider from "./auth/authProvider";
import { router } from "./router";
import { getSentryConfig } from "./sentryConfig";
import { GlobalStyles } from "./style";

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
        },
    },
});

Sentry.init(getSentryConfig());

function App() {
    return (
        <TooltipProvider delayDuration={100}>
            <QueryClientProvider client={queryClient}>
                <AuthProvider>
                    <GlobalStyles />
                    <RouterProvider router={router} />
                </AuthProvider>
                <ReactQueryDevtools initialIsOpen={false} position="bottom" />
            </QueryClientProvider>
        </TooltipProvider>
    );
}

export default App;
