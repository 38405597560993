import React from "react";
import { AuthUser } from "../api/apiMethods";

export interface AuthContextObject extends AuthUser {
    identityId: string;
    municipalityName?: string;
}

export const UserContext = React.createContext<{
    setAuthUserData: React.Dispatch<
        React.SetStateAction<AuthContextObject | undefined>
    >;
    authUserData?: AuthContextObject;
}>({
    setAuthUserData: () => null,
});
