import React from "react";
import { Link } from "react-router-dom";
import styled, { css } from "styled-components";
import colors from "../../common/colors";
import mixins from "../../common/mixins";
import { breakpoint, fontSize } from "../../common/variables";

// styled-components and ts are being d**ks together
export const Wrapper = styled(({ noLink, ...rest }) => {
    if (noLink) {
        return <div {...rest}></div>;
    } else {
        return <Link {...rest} />;
    }
})`
    display: flex;
    position: relative;
    height: 70px;
    flex-grow: 1;
    flex-shrink: 1;
    transition: box-shadow ${mixins.transitions.default};

    ${({ noLink }: { noLink?: boolean }) => {
        // if a link then show hover transition
        if (!noLink) {
            return css`
                &:before,
                &:after {
                    content: "";
                    height: 99%;
                    width: 10px;
                    position: absolute;
                    background: ${colors.white};
                    top: 1px;
                    transition: transform ${mixins.transitions.default};
                }

                &:before {
                    left: 0;
                    border-radius: ${mixins.borderRadius.default} 0 0
                        ${mixins.borderRadius.default};
                }

                &:after {
                    right: 0;
                    border-radius: 0 ${mixins.borderRadius.default}
                        ${mixins.borderRadius.default} 0;
                }

                &:last-of-type {
                    border-bottom-left-radius: ${mixins.borderRadius.default};
                    border-bottom-right-radius: ${mixins.borderRadius.default};
                    overflow: hidden;
                }

                @media ${breakpoint.large} {
                    &:hover {
                        box-shadow: ${mixins.boxShadow.default};
                        z-index: 1;

                        &:before {
                            transform: translateX(-10px);
                        }

                        &:after {
                            transform: translateX(10px);
                        }

                        &:last-of-type {
                            overflow: visible;
                        }
                    }
                }
            `;
        }
    }}
`;

const Content = styled.div`
    display: flex;
    height: 100%;
    width: 100%;
    justify-content: space-between;
    gap: var(--tableColumnGap);
    padding: 0 var(--tableColumnGap);

    ${({ modifiedTheme }: { modifiedTheme?: boolean }) =>
        modifiedTheme
            ? css`
                  background-color: ${colors.grayLighter};
                  border-radius: ${mixins.borderRadius.default};
              `
            : css`
                  border-top: 1px solid ${colors.grayLighter};
                  background-color: ${colors.white};
              `}
`;

const Cell = styled.div<{
    rowLength?: number;
    fixedWidth?: number;
    growWidth?: boolean;
}>`
    display: flex;
    align-items: center;
    flex-shrink: 0;

    flex-grow: ${({ growWidth }) => (growWidth ? 1 : 0)};
    color: ${colors.black};
    width: ${({ rowLength, fixedWidth }) => {
        if (fixedWidth !== undefined) {
            return `${fixedWidth}px;`;
        } else {
            if (rowLength !== undefined)
                return `calc(${100 / rowLength}% - var(--tableColumnGap));`;
        }
        return undefined;
    }};
`;

const Title = styled.span`
    font-size: ${fontSize.small};
    line-height: ${fontSize.xxLarge};
    text-overflow: ellipsis;
    overflow: hidden;
`;

export const TableRowStyles = {
    Wrapper,
    Content,
    Cell,
    Title,
};
