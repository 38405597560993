import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useContext } from "react";
import { unitMethods } from "../api/apiMethods";
import { TimeRestriction } from "../common/types";
import { UserContext } from "../contexts/UserContext";
import { queryKeys } from "../queries/queryKeys";
import { UnitInfoFormData } from "../views/UnitView/UnitView.hooks";
import { convertUnitForInput } from "../views/UnitView/unitDetailsConfig";

export const useCreateUnitMutation = (options: {
    onSuccess: (data: string) => void;
    onError: (error: Error) => void;
}) => {
    const { authUserData } = useContext(UserContext);
    const queryClient = useQueryClient();

    const mutation = useMutation({
        mutationFn: async (params: {
            input: UnitInfoFormData;
            selectedTimeFrames: TimeRestriction[];
        }) => {
            if (!authUserData?.municipalityCode) {
                throw new Error("No auth user data or no municipality code");
            }

            try {
                const unitId = (
                    await unitMethods(authUserData.municipalityCode).createUnit(
                        convertUnitForInput(params.input)
                    )
                ).municipalityUnitID;

                if (!unitId) {
                    throw new Error(
                        "Could not create a unit. No unit id was returned"
                    );
                }

                await unitMethods(
                    authUserData.municipalityCode
                ).updateUnitRestrictions(params.selectedTimeFrames, unitId);

                return unitId;
            } catch (err) {
                throw err;
            }
        },
        onSuccess: (data) => {
            options.onSuccess(data);
            queryClient.invalidateQueries({
                queryKey: queryKeys.unit.all(authUserData?.municipalityCode),
            });
        },
        onError: options.onError,
    });

    return mutation;
};
