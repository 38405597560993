import React, { useContext, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import assets from "../../common/assets";
import { genericErrorConfig } from "../../common/exceptions";
import { ListOption } from "../../common/types";
import { isHybrisValidationError } from "../../common/validations";
import { space } from "../../common/variables";
import Button from "../../components/Button";
import Container from "../../components/Container";
import Cell, { CellHeader, CellHeading } from "../../components/Grid/Cell";
import CellBlock, {
    CellBlockFooter,
    CellBlockSection,
    CellBlockValue,
} from "../../components/Grid/CellBlock";
import Grid from "../../components/Grid/Grid";
import { ModalContext } from "../../components/Modal";
import OptionsList from "../../components/OptionsList";
import Plate from "../../components/Plate";
import SubHeader from "../../components/SubHeader";
import Tag from "../../components/Tag";
import { useCreateOrdererMutation } from "../../mutations/useCreateOrdererMutation";
import { useAllUnitsQuery } from "../../queries/units/useAllUnitsQuery";
import { extractFieldErrorsFromHybrisErrorAsArray } from "../CustomerView/customerDetailsConfig";
import { useOrdererInfoForm } from "./OrdererDetailsView.hooks";
import OrdererInfoForm from "./OrdererInfoForm";
import {
    convertOrdererForInput,
    getModalOrdererErrorText,
    mapHybrisOrdererErrorField,
} from "./ordererDetailsConfig";
import { OrdererInput } from "./ordererTypes";

const CreateOrdererView = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const { setModalOptions } = useContext(ModalContext);

    const [isEditingUnits, setIsEditingUnits] = useState(false);

    const [selectedUnits, setSelectedUnits] = useState<ListOption[]>([]);
    function updateUnitList(selectedUnit: ListOption) {
        const alreadySelected = selectedUnits.find(
            (unit) => unit.id === selectedUnit.id
        );

        if (alreadySelected) {
            const updatedUnits = [...selectedUnits].filter(
                (unit) => unit.id !== selectedUnit.id
            );
            setSelectedUnits(updatedUnits);
        } else {
            setSelectedUnits([...selectedUnits, selectedUnit]);
        }
    }

    const allUnitsQuery = useAllUnitsQuery();
    const allUnitsOptions: ListOption[] = useMemo(
        () =>
            allUnitsQuery.data?.map((item) => ({
                id: item.code,
                name: item.name,
            })) || [],
        [allUnitsQuery.data]
    );

    const createOrdererMutation = useCreateOrdererMutation({
        onSuccess: (createdOrdererId) => {
            navigate(
                `${location.pathname.substr(
                    0,
                    location.pathname.lastIndexOf("/")
                )}/${createdOrdererId}`,

                { replace: true }
            );
        },
        onError: (error) => {
            setModalOptions(
                genericErrorConfig(
                    () => setModalOptions({ visible: false }),
                    "Kunde ej skapa beställare.",
                    getModalOrdererErrorText(error)
                )
            );

            if (isHybrisValidationError(error)) {
                const fieldErrors = extractFieldErrorsFromHybrisErrorAsArray(
                    error,
                    mapHybrisOrdererErrorField
                );

                fieldErrors.forEach((element) => {
                    form.setError(element.fieldName as any, {
                        message: element.message,
                        type: "required",
                    });
                });
            }
        },
    });

    const createOrderer = (input: OrdererInput) => {
        createOrdererMutation.mutate(input);
    };

    const form = useOrdererInfoForm();

    const cancelEditingUnits = () => {
        setSelectedUnits([]);
        setIsEditingUnits(false);
    };

    const isMutationLoading = createOrdererMutation.isPending;

    return (
        <Container>
            <SubHeader backArrow title={"Skapa ny beställare"} />
            <Plate padding="large" spaceBottom={true}>
                <Grid gutter={space.padding}>
                    <Cell
                        gutter={space.padding}
                        extraPaddingRight={space.xLarge}>
                        <CellHeading>Personuppgifter</CellHeading>
                        <CellBlock active={true} noOutlines={true}>
                            <OrdererInfoForm
                                onCancel={() => navigate(-1)}
                                {...form}
                                isSubmitLoading={isMutationLoading}
                                isNewForm={true}
                                onSubmit={(data) =>
                                    createOrderer(
                                        convertOrdererForInput(
                                            data,
                                            selectedUnits
                                        )
                                    )
                                }
                            />
                        </CellBlock>
                    </Cell>
                    <Cell
                        gutter={space.padding}
                        extraPaddingLeft={space.xLarge}>
                        <CellHeader>
                            <CellHeading>
                                Enheter
                                {selectedUnits?.length > 0 &&
                                    ` (${selectedUnits.length})`}
                            </CellHeading>
                            {isEditingUnits && selectedUnits.length > 0 && (
                                <div>
                                    <Tag>
                                        {selectedUnits.length === 1
                                            ? `1 vald enhet`
                                            : `${selectedUnits.length} valda enheter`}
                                    </Tag>
                                </div>
                            )}
                        </CellHeader>
                        {selectedUnits?.length || isEditingUnits ? (
                            <CellBlock
                                onEdit={cancelEditingUnits}
                                active={isEditingUnits}>
                                {isEditingUnits ? (
                                    <>
                                        <CellBlockSection>
                                            <CellBlockValue>
                                                Välj vilka enheter som ska vara
                                                kopplade till beställaren.
                                            </CellBlockValue>
                                        </CellBlockSection>
                                        <CellBlockSection>
                                            {!!allUnitsOptions?.length && (
                                                <OptionsList
                                                    overflowValue={space.medium}
                                                    setChoice={updateUnitList}
                                                    data={allUnitsOptions}
                                                    selectedData={selectedUnits}
                                                />
                                            )}
                                        </CellBlockSection>
                                        <CellBlockFooter
                                            isLoading={isMutationLoading}
                                            onCancel={cancelEditingUnits}
                                        />
                                    </>
                                ) : (
                                    <>
                                        {selectedUnits.map((unit) => (
                                            <CellBlockValue key={unit.id}>
                                                {unit.name}
                                            </CellBlockValue>
                                        ))}
                                    </>
                                )}
                            </CellBlock>
                        ) : (
                            <Button
                                outsideTitle="Lägg till enheter"
                                shape="round"
                                onClick={() => setIsEditingUnits(true)}
                                icon={assets.plus}
                            />
                        )}
                    </Cell>
                </Grid>
            </Plate>
        </Container>
    );
};

export default CreateOrdererView;
