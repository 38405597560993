interface Mixins {
    boxShadow: {
        spread: string;
        small: string;
        default: string;
        bottomAngle: string
    }
    borderRadius: {
        default: string
    }
    transitions: {
        default: string
        large: string
    }
}

const mixins: Mixins = {
    boxShadow: {
        spread: `0 4px 20px 0 rgba(0, 0, 0, 0.08)`,
        small: `0 0 8px 0 rgba(0, 0, 0, 0.05)`,
        default: `0 0 10px 0 rgba(0, 0, 0, 0.12)`,
        bottomAngle: `5px 5px 10px 0 rgba(0, 0, 0, 0.12)`
    },
    borderRadius: {
        default: "8px"
    },
    transitions: {
        default: "0.2s",
        large: "0.5s"
    }
};

export default mixins;
