import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { z } from "zod";
import {
    emailInvalidMessage,
    isRequiredMessage,
    phoneInvalidMessage,
    phoneNrPattern,
} from "../../common/validations";

const ordererInfoFormSchema = z.object({
    firstName: z.string().min(1, { message: isRequiredMessage }),
    lastName: z.string().min(1, { message: isRequiredMessage }),
    phone: z
        .string()
        .regex(phoneNrPattern, { message: phoneInvalidMessage })
        .min(1, { message: isRequiredMessage }),
    email: z.string().email({ message: emailInvalidMessage }),
    customerCreationAllowed: z.boolean().optional().default(false),
});

export type OrdererInfoFormData = z.infer<typeof ordererInfoFormSchema>;

export const useOrdererInfoForm = () => {
    const form = useForm<OrdererInfoFormData>({
        resolver: zodResolver(ordererInfoFormSchema),
        mode: "onTouched",
    });

    return form;
};
