import { daysConsistent, DaysConsistentEnum } from "../translations";
import { TimeFrameListSection, TimeRestriction } from "../types";

/**
 * Groups an array of time restrictions, mapping each weekday with each matching time.
 * @param data Time restrictions.
 * @param minified Option to recieve short names and times.
 */
export const groupTimeRestrictions = (data: TimeRestriction[]) => {
    const sortedData = [...data].sort((a, b) => {
        if (a.startTime < b.startTime || a.endTime < b.endTime) {
            return -1;
        }
        if (b.startTime < a.startTime || b.endTime < a.endTime) {
            return 1;
        }
        return 0;
    });

    const groupedData = sortedData.reduce((array, item) => {
        const matchingSection = array.find(
            (o) => DaysConsistentEnum[o.dayOfWeek] === item.dayOfWeek
        );

        if (matchingSection) {
            matchingSection.times.push({
                startTime: item.startTime,
                endTime: item.endTime,
            });
        } else {
            array.push({
                dayOfWeek: daysConsistent[item.dayOfWeek].name,
                times: [
                    {
                        startTime: item.startTime,
                        endTime: item.endTime,
                    },
                ],
            });
        }

        return array;
    }, [] as TimeFrameListSection[]);

    groupedData.sort((a, b) => {
        const orderA = daysConsistent[DaysConsistentEnum[a.dayOfWeek]].order;
        const orderB = daysConsistent[DaysConsistentEnum[b.dayOfWeek]].order;

        if (orderA < orderB) {
            return -1;
        }
        if (orderB < orderA) {
            return 1;
        }
        return 0;
    });

    return groupedData;
};
