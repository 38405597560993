import { useQuery } from "@tanstack/react-query";
import { useContext } from "react";
import { municipalityMethods } from "../api/apiMethods";
import { UserContext } from "../contexts/UserContext";
import { useQueryErrorHandling } from "../hooks/useQueryErrorHandling";
import { queryKeys } from "./queryKeys";

export const useMunicipalityRestrictionsQuery = () => {
    const { authUserData } = useContext(UserContext);

    const fetchAuthorized =
        authUserData && authUserData.municipalityRole === "ADMIN";

    const query = useQuery({
        queryKey: queryKeys.municipality.restrictions(
            authUserData?.municipalityCode
        ),
        queryFn: () => {
            if (!authUserData?.municipalityCode) {
                throw new Error("Missing municipality code");
            }

            try {
                return municipalityMethods(
                    authUserData.municipalityCode
                ).getMunicipalityRestrictions();
            } catch (error) {
                throw new Error("Could not get municipality restrictions");
            }
        },
        enabled: fetchAuthorized,
        select: ({ homeTimeWindowRestrictions }) => homeTimeWindowRestrictions,
    });

    useQueryErrorHandling(query, "Kunde inte hämta tidsfönster.");

    return query;
};
