import React, { useContext, useEffect, useState } from "react";
import { AuthContext } from "../auth/authProvider";
import Button from "../components/Button";
import Loader from "../components/Loader";
import { ModalContext } from "../components/Modal";
import { UserContext } from "../contexts/UserContext";
import { telemetry } from "../utils/telemetry/TelemetryService";

interface Props {
    children: React.ReactNode;
}

const Authorized = (props: Props) => {
    const { signout } = useContext(AuthContext);
    const { authUserData } = useContext(UserContext);
    const { setModalOptions } = useContext(ModalContext);

    const [isAuthorized, setIsAuthorized] = useState<unknown>(undefined);

    useEffect(() => {
        if (authUserData) {
            if (
                authUserData.municipalityRole === "ADMIN" ||
                authUserData.municipalityRole === "ORDERER"
            ) {
                setIsAuthorized(true);
            } else {
                setIsAuthorized(false);
                telemetry.trackInfo("Access Denied", {
                    role: authUserData.municipalityRole,
                    municipality: authUserData.municipalityName,
                    municipalityCode: authUserData.municipalityCode,
                    municipalityUnitCode: authUserData.municipalityUnitCode,
                });
            }
        }
    }, [authUserData]);

    useEffect(() => {
        if (isAuthorized === false) {
            setModalOptions({
                title: "Du är inte behörig att nyttja Ombudshandel Online",
                subtitle:
                    "Prova logga in och ut igen om du blev automatiskt inloggad så det är säkert att du nyttjar rätt konto. Om det inte fungerar och du tycker att du borde ha behörighet att logga in, ta kontakt med din kontaktperson hos din kommun.",
                visible: true,
                lockDismiss: true,
                buttons: [
                    <Button
                        size="small"
                        title="Logga ut"
                        onClick={() => {
                            signout();
                        }}
                    />,
                ],
            });
        }
    }, [isAuthorized, authUserData, signout, setModalOptions]);

    if (isAuthorized === undefined) {
        return <Loader />;
    }

    if (isAuthorized === true) {
        return <>{props.children}</>;
    }

    return null;
};

export default Authorized;
