import React from "react";
import colors from "../../common/colors";
import AnchorLink from "../../components/AnchorLink";
import Checkbox from "../../components/Checkbox";
import FormInvalidError from "../../components/FormInvalidError";
import {
    CellBlockField,
    CellBlockProperty,
    CellBlockSection,
    CellBlockTitle,
    CellBlockValue,
} from "../../components/Grid/CellBlock";
import { OrdererInfoData } from "./OrdererInfoForm";

interface OrdererInfoListingProps {
    data: OrdererInfoData;
    isValid: boolean;
}

const OrdererInfoListing = (props: OrdererInfoListingProps) => {
    const { firstName, lastName, phone, email, customerCreationAllowed } =
        props.data;

    return (
        <>
            <CellBlockSection>
                <CellBlockTitle>{`${firstName} ${lastName}`}</CellBlockTitle>
            </CellBlockSection>
            <CellBlockSection>
                <CellBlockProperty>Telefon</CellBlockProperty>
                <CellBlockValue>{phone}</CellBlockValue>
            </CellBlockSection>
            <CellBlockSection>
                <CellBlockProperty>E-postadress</CellBlockProperty>
                <CellBlockField>
                    <AnchorLink>{email}</AnchorLink>
                </CellBlockField>
            </CellBlockSection>
            {customerCreationAllowed && (
                <CellBlockSection>
                    <Checkbox
                        value={true}
                        text="Får lägga till nya brukare"
                        iconColor={colors.grayDark}
                        readOnly
                    />
                </CellBlockSection>
            )}
            {!props.isValid && (
                <CellBlockSection>
                    <FormInvalidError />
                </CellBlockSection>
            )}
        </>
    );
};

export default OrdererInfoListing;
