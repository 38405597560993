import { TimeRestriction } from "../../common/types";
import { UnitInfoFormData } from "./UnitView.hooks";
import { Unit, UnitInput } from "./unitTypes";

export const convertUnitFromOutput = (data?: Unit) =>
    ({
        name: data?.name || "",
        line1: data?.contact?.line1 || "",
        postalCode: data?.contact?.postalCode || "",
        town: data?.contact?.town || "",
        phone: data?.contact?.phone || "",
        doorCode: data?.contact?.doorCode || "",
        stairs: data?.contact?.stairs || "",
        // comments: data?.municipalityUnitComments || "",
    } as UnitInfoFormData);

export const convertUnitForInput = (data: UnitInfoFormData) =>
    ({
        unitName: data.name,
        line1: data.line1,
        postalCode: data.postalCode,
        town: data.town,
        phone: data.phone,
        doorCode: data.doorCode,
        stairs: data.stairs,
        // municipalityUnitComments: data.comments,
        email: "",
        firstName: "",
        lastName: "",
        line2: "",
    } as UnitInput);

export const convertMunicipalityRestrictionsFromOutput = (
    restrictions: TimeRestriction[]
) =>
    restrictions.map<TimeRestriction>((res) => ({
        dayOfWeek: res.dayOfWeek,
        startTime: res.startTime.slice(0, -3),
        endTime: res.endTime.slice(0, -3),
    }));
