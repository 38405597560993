import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useContext } from "react";
import { customerMethods } from "../../api/apiMethods";
import { UserContext } from "../../contexts/UserContext";
import { queryKeys } from "../../queries/queryKeys";
import { Logger } from "../../utils/logger/Logger";

export const useCustomerMatkontoDelete = (options: {
    onSuccess?: () => void;
    onError: () => void;
}) => {
    const { authUserData } = useContext(UserContext);
    const queryClient = useQueryClient();

    const mutation = useMutation({
        mutationFn: async ({
            unitId,
            customerId,
            tokenId,
        }: {
            unitId: string;
            customerId: string;
            tokenId: string;
        }) => {
            if (!authUserData) {
                throw new Error("No auth user data");
            }

            try {
                await customerMethods(
                    authUserData.municipalityCode
                ).deleteMatkontoCard(unitId, customerId, tokenId);
            } catch (err) {
                throw err;
            }
        },
        onSuccess: (_data, variables) => {
            queryClient.invalidateQueries({
                queryKey: queryKeys.customer.byId(variables.customerId),
            });
            options.onSuccess?.();
        },
        onError: async (response: Response) => {
            Logger.error(response);

            let jsonError;
            try {
                jsonError = await response.json();
                Logger.error(jsonError);
            } catch {}

            options.onError();
        },
    });

    return mutation;
};
