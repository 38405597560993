import React, { useContext } from "react";
import Button from "../components/Button";
import { ModalContext } from "../components/Modal";

const useUnsavedChangesModal = () => {
    const { setModalOptions } = useContext(ModalContext);

    const showUnsavedChangesModal = (action: () => void) => {
        setModalOptions({
            visible: true,
            title: "Osparade ändringar",
            subtitle:
                "Du har osparade ändringar i den nuvarande sektionen. Vill du ändå byta?",
            buttons: [
                <Button
                    size="small"
                    title="Fortsätt"
                    onClick={() => {
                        action();
                        setModalOptions({ visible: false });
                    }}
                />,
                <Button
                    size="small"
                    title="Avbryt"
                    theme="inverted"
                    onClick={() => setModalOptions({ visible: false })}
                />,
            ],
        });
    };

    return showUnsavedChangesModal;
};

export default useUnsavedChangesModal;
