export const days = [
    "Söndag",
    "Måndag",
    "Tisdag",
    "Onsdag",
    "Torsdag",
    "Fredag",
    "Lördag",
];

export type DaysConsistentType =
    | "Måndagar"
    | "Tisdagar"
    | "Onsdagar"
    | "Torsdagar"
    | "Fredagar"
    | "Lördagar"
    | "Söndagar";

export enum DaysConsistentEnum {
    Måndagar = "MONDAY",
    Tisdagar = "TUESDAY",
    Onsdagar = "WEDNESDAY",
    Torsdagar = "THURSDAY",
    Fredagar = "FRIDAY",
    Lördagar = "SATURDAY",
    Söndagar = "SUNDAY",
}

export const daysConsistent: {
    [key: string]: {
        name: DaysConsistentType;
        order: string;
    };
} = {
    MONDAY: { name: "Måndagar", order: "a" },
    TUESDAY: { name: "Tisdagar", order: "b" },
    WEDNESDAY: { name: "Onsdagar", order: "c" },
    THURSDAY: { name: "Torsdagar", order: "d" },
    FRIDAY: { name: "Fredagar", order: "e" },
    SATURDAY: { name: "Lördagar", order: "f" },
    SUNDAY: { name: "Söndagar", order: "g" },
};

export const months = [
    "januari",
    "februari",
    "mars",
    "april",
    "maj",
    "juni",
    "juli",
    "augusti",
    "september",
    "oktober",
    "november",
    "december",
];

export type OrderStatus =
    | "På väg"
    | "Levererad"
    | "Makulerad"
    | "Beställd"
    | "Packas"
    | "Okänt";

export const PaymentTypesValues = [
    "Faktura",
    "Matkonto",
    "Samlingsfaktura",
    "Okänt",
] as const;

export type PaymentTypes = (typeof PaymentTypesValues)[number];
