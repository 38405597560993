/* eslint-disable no-useless-escape */
import type { BrowserOptions } from "@sentry/browser";
import * as Sentry from "@sentry/react";
import { useEffect } from "react";
import {
    createRoutesFromChildren,
    matchRoutes,
    useLocation,
    useNavigationType,
} from "react-router";
import { appConfig } from "./appConfig";

const baseSentryConfig: BrowserOptions = {
    dsn: "https://45fe35f69ff7231fc69422b970fbcb8e@o4506542918270976.ingest.us.sentry.io/4507253613264896",
    integrations: [
        Sentry.reactRouterV6BrowserTracingIntegration({
            createRoutesFromChildren: createRoutesFromChildren,
            useEffect: useEffect,
            useLocation: useLocation,
            useNavigationType: useNavigationType,
            matchRoutes: matchRoutes,
        }),
        Sentry.replayIntegration(),
    ],
    ignoreTransactions: [
        new RegExp("/units/(.*)/restrictions"),
        new RegExp("/units/(.*)/windowreplacementconfigurations"),
    ],
};

type SentryEnvironment = "LOCAL" | "DEV" | "QA" | "PRODUCTION";

export const getSentryConfig = (): BrowserOptions => {
    let envBasedOptions: Partial<BrowserOptions> = {};
    let sentryEnviornment: SentryEnvironment | undefined;

    if (appConfig.MODE === "production") {
        sentryEnviornment = "PRODUCTION";
        envBasedOptions = {
            sampleRate: 1,
            tracesSampleRate: 0.1,
            replaysOnErrorSampleRate: 1,
            replaysSessionSampleRate: 0,
        };
    }

    if (appConfig.MODE === "qa") {
        sentryEnviornment = "QA";
        envBasedOptions = {
            sampleRate: 0.05,
            tracesSampleRate: 0.05,
            replaysOnErrorSampleRate: 0.05,
            replaysSessionSampleRate: 0,
        };
    }

    if (appConfig.MODE === "development") {
        sentryEnviornment = "DEV";
        envBasedOptions = {
            sampleRate: 0,
            tracesSampleRate: 0,
            replaysOnErrorSampleRate: 0,
            replaysSessionSampleRate: 0,
        };
    }

    return {
        ...baseSentryConfig,
        environment: sentryEnviornment,
        ...envBasedOptions,
    };
};
