import { UseQueryResult } from "@tanstack/react-query";
import { useContext, useEffect } from "react";
import { genericErrorConfig } from "../common/exceptions";
import { ModalContext } from "../components/Modal";
import { Logger } from "../utils/logger/Logger";
import { useSavedInRef } from "./useSavedInRef";

export const useQueryErrorHandling = (
    query: UseQueryResult,
    errorTitle: string,
    genericErrorCallback?: () => void
) => {
    const { setModalOptions } = useContext(ModalContext);
    const callback = useSavedInRef(genericErrorCallback);

    useEffect(() => {
        if (query.isError) {
            Logger.error(query.error);
            setModalOptions(
                genericErrorConfig(() => {
                    setModalOptions({ visible: false });
                    callback.current?.();
                }, errorTitle)
            );
        }
    }, [callback, errorTitle, query.error, query.isError, setModalOptions]);
};
