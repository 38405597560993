import React from "react";
import styled from "styled-components";
import assets from "../common/assets";
import colors from "../common/colors";

const FormInvalidError = ({ error }: { error?: string }) => {
    return (
        <ErrorWrapper>
            <Icon>{assets.exclamation}</Icon>
            <span>{error || `Viss information är ogiltig eller saknas! `}</span>
        </ErrorWrapper>
    );
};

const Icon = styled.div`
    min-height: 20px;
    max-height: 20px;
    min-width: 20px;
    max-width: 20px;
    display: inline-block;
    margin-right: 10px;
    margin-top: -1px;
    fill: ${colors.red};
`;

const ErrorWrapper = styled.div`
    color: ${colors.red};
    display: flex;
    align-items: center;
`;

export default FormInvalidError;
