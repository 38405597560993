/**
 * This is mostly for extracting and calculating pixel values for Styled Components,
 * since it only takes a string as value. So instead of having to write px after each value
 * I created this helper to extract the value from the string in case one needs to make some calculations.
 * @param {string} valueString The value to be extraced
 * @param {string} [replaceString = ""] Something to replace the "px" with
 * @returns {number} number
 */
const extractValue = (valueString: string, replaceString: string = "") =>
    parseInt(valueString.replace("px", replaceString))

export default extractValue;