import React from "react";
import styled from "styled-components";
import extractValue from "../../common/helpers/extractValue";
import { breakpoint, fontSize, space } from "../../common/variables";

const Cell = ({
    children,
    gutter,
    extraPaddingLeft,
    extraPaddingRight,
}: {
    children: React.ReactNode;
    gutter?: string;
    extraPaddingLeft?: string;
    extraPaddingRight?: string;
}) => (
    <Wrapper
        gutter={gutter}
        paddingLeft={extraPaddingLeft}
        paddingRight={extraPaddingRight}>
        {children}
    </Wrapper>
);

const Wrapper = styled.div<{
    gutter?: string;
    paddingLeft?: string;
    paddingRight?: string;
}>`
    flex-shrink: 0;
    flex-grow: 0;
    font-size: ${fontSize.small};
    padding-left: ${({ gutter, paddingLeft }) => {
        let value = 0;

        if (gutter) {
            value = +(extractValue(gutter) / 2);
        }
        return `${value}px`;
    }};

    padding-right: ${({ gutter, paddingRight }) => {
        let value = 0;

        if (gutter) {
            value = +(extractValue(gutter) / 2);
        }
        return `${value}px`;
    }};

    @media ${breakpoint.large} {
        width: 50%;
        padding-left: ${({ gutter, paddingLeft }) => {
            let value = 0;
    
            if (gutter) {
                value = +(extractValue(gutter) / 2);
            }
    
            if (paddingLeft) {
                value = +extractValue(paddingLeft);
            }
            return `${value}px`;
        }};
    
        padding-right: ${({ gutter, paddingRight }) => {
            let value = 0;
    
            if (gutter) {
                value = +(extractValue(gutter) / 2);
            }
    
            if (paddingRight) {
                value = +extractValue(paddingRight);
            }
            return `${value}px`;
        }};
    }

    &:not(:last-of-type) {
        margin-bottom: ${space.padding};
    }

    @media ${breakpoint.large} {
        &:not(:last-of-type) {
            margin-bottom: 0;
        }
    }
`;

export const CellHeader = styled.div`
    display: flex;
    justify-content: space-between;
`;

export const CellHeading = styled.h2`
    font-size: ${fontSize.medium};
    font-weight: 400;
    margin-top: 0;
    margin-bottom: ${space.medium};
`;

export default Cell;
