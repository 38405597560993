import styles from './Search.module.scss';
import { SearchSize, SearchTheme } from './Search.types';

export const getClassForTheme = (theme?: SearchTheme) => {
    switch (theme) {
        case 'greenBorder':
            return styles['Search--greenBorder'];
        default:
            return '';
    }
};

export const getClassForSize = (theme?: SearchSize) => {
    switch (theme) {
        case 'small':
            return styles['Search--small'];
        case 'medium':
            return styles['Search--medium'];
        case 'large':
            return styles['Search--large'];
        default:
            return '';
    }
};
