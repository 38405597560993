import React from "react";
import styled, { css } from "styled-components";
import colors from "../common/colors";
import extractValue from "../common/helpers/extractValue";
import mixins from "../common/mixins";
import { breakpoint, space } from "../common/variables";

interface Props {
    spaceTop?: boolean;
    spaceBottom?: boolean;
    padding?: "small" | "medium" | "large" | "xLarge";
    paddingTop?: "small" | "medium" | "large" | "xLarge" | "none";
    faded?: boolean;
    outerChildren?: React.ReactNode;
    noShadow?: boolean;
    children?: React.ReactNode;
    overScroll?: boolean;
    style?: React.CSSProperties;
}

const Plate = (props: Props) => {
    return (
        <Wrapper
            noShadow={props.noShadow}
            spaceTop={props.spaceTop}
            overScroll={props.overScroll}
            faded={props.faded}
            style={props.style}
            spaceBottom={props.spaceBottom}>
            {props.outerChildren}
            <Content padding={props.padding} paddingTop={props.paddingTop}>
                {props.children}
            </Content>
        </Wrapper>
    );
};

export default Plate;

const Wrapper = styled.div<{
    spaceBottom?: boolean;
    spaceTop?: boolean;
    noShadow?: boolean;
    overScroll?: boolean;
    faded?: boolean;
}>`
    height: 100%;
    background-color: ${colors.white};
    border-radius: ${mixins.borderRadius.default};
    opacity: ${({ faded }) => (faded ? 0.5 : 1)};
    box-shadow: ${({ noShadow }) =>
        noShadow ? "none" : mixins.boxShadow.spread};
    transition: opacity ${mixins.transitions.default};

    ${({ overScroll }) =>
        overScroll &&
        css`
            overflow: auto;
            @media ${breakpoint.large} {
                overflow: visible;
            }
        `}

    ${({ spaceBottom, spaceTop }) => {
        if (spaceTop && spaceBottom) {
            return css`
                margin-top: ${space.paddingSmall};
                margin-bottom: ${space.paddingSmall};

                @media ${breakpoint.small} {
                    margin-top: ${space.padding};
                    margin-bottom: ${space.padding};
                }
            `;
        }

        if (spaceTop) {
            return css`
                margin-top: ${space.paddingSmall};

                @media ${breakpoint.small} {
                    margin-top: ${space.padding};
                }
            `;
        }

        if (spaceBottom) {
            return css`
                margin-bottom: ${space.paddingSmall};

                @media ${breakpoint.small} {
                    margin-bottom: ${space.padding};
                }
            `;
        }

        return css`
            margin-bottom: 0;
        `;
    }};
`;

const getPaddingSize = (
    padding: "small" | "medium" | "large" | "xLarge" | "none"
) => {
    switch (padding) {
        case "small":
            return space.paddingSmall;
        case "medium":
            return space.padding;
        case "large":
            return space.padding;
        case "xLarge":
            return `${extractValue(space.padding) * 2}px`;
        case "none":
            return "0px";
    }
};

const Content = styled.div<{
    padding?: "small" | "medium" | "large" | "xLarge";
    paddingTop?: "small" | "medium" | "large" | "xLarge" | "none";
}>`
    ${({ padding }) => {
        if (padding) {
            return css`
                padding: ${getPaddingSize(padding)};
            `;
        }

        return null;
    }};

    ${({ paddingTop }) => {
        if (paddingTop) {
            return css`
                padding-top: ${getPaddingSize(paddingTop)};
            `;
        }

        return null;
    }};
`;
