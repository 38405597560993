import React from "react";
import styled, { css } from "styled-components";
import colors from "../../common/colors";
import mixins from "../../common/mixins";
import { breakpoint, fontSize, space } from "../../common/variables";
import AnchorLink from "../AnchorLink";
import Button from "../Button";

export const CellBlockFooter = ({
    onSubmit,
    onCancel,
    disableSubmit,
    hasChangesText,
    hideInDesktop,
    hideInMobile,
    marginTop,
    isLoading,
    submitButtonType,
}: {
    onSubmit?: () => void;
    onCancel?: () => void;
    disableSubmit?: boolean;
    hasChangesText?: string;
    hideInDesktop?: boolean;
    hideInMobile?: boolean;
    marginTop?: boolean;
    isLoading?: boolean;
    submitButtonType?: "button" | "submit" | "reset";
}) => (
    <Footer
        marginTop={marginTop}
        hideInDesktop={hideInDesktop}
        hideInMobile={hideInMobile}>
        {(onSubmit || submitButtonType === "submit") && (
            <Button
                type={submitButtonType}
                size="small"
                title="Spara"
                disabled={disableSubmit}
                onClick={onSubmit}
                isLoading={isLoading}
                wrapperStyle={{ marginRight: space.medium }}
            />
        )}
        {onCancel && (
            <Button
                type="button"
                size="small"
                theme="inverted"
                title="Avbryt"
                onClick={onCancel}
            />
        )}
        {hasChangesText?.length && <ChangesText>{hasChangesText}</ChangesText>}
    </Footer>
);

const CellBlock = ({
    onEdit,
    active,
    noOutlines,
    children,
    style,
}: {
    onEdit?: () => void;
    active?: boolean;
    noOutlines?: boolean;
    children: React.ReactNode;
    style?: React.CSSProperties;
}) => {
    return (
        <Wrapper active={active} noOutlines={noOutlines} style={style}>
            {onEdit && !active && <Edit onClick={onEdit}>Ändra</Edit>}
            {children}
        </Wrapper>
    );
};

const Wrapper = styled.div<{
    active?: boolean;
    noOutlines?: boolean;
}>`
    width: 100%;
    min-height: 80px;
    position: relative;
    padding: ${({ noOutlines }) => (noOutlines ? 0 : space.medium)};
    border-radius: ${mixins.borderRadius.default};
    border: ${({ noOutlines }) =>
        noOutlines ? "none" : `1px solid ${colors.border}`};
    transition: box-shadow ${mixins.transitions.default};

    > div:last-of-type {
        margin-bottom: 0;
    }

    ${({ active, noOutlines }) =>
        active &&
        !noOutlines &&
        css`
            box-shadow: ${mixins.boxShadow.bottomAngle};
        `}
`;

const Footer = styled.div<{
    marginTop?: boolean;
    hideInDesktop?: boolean;
    hideInMobile?: boolean;
}>`
    margin-top: ${({ marginTop }) => (marginTop ? space.medium : 0)};
    ${({ hideInDesktop, hideInMobile }) => {
        if (hideInDesktop) {
            return css`
                display: flex;
                @media ${breakpoint.large} {
                    display: none;
                }
            `;
        }

        if (hideInMobile) {
            return css`
                display: none;
                @media ${breakpoint.large} {
                    display: flex;
                }
            `;
        }

        return css`
            display: flex;
        `;
    }};
`;

const Edit = styled(AnchorLink)`
    position: absolute;
    top: ${space.medium};
    right: ${space.small};
`;

export const CellBlockSection = styled.div`
    margin-bottom: ${space.medium};
`;

export const CellBlockProperty = styled.p`
    margin: 0 ${space.xxSmall} ${space.xxSmall} 0;
`;

export const CellBlockTitle = styled.span`
    display: block;
    font-weight: 700;
    margin: 0 0 ${space.small} 0;

    &:last-child {
        margin: 0;
    }
`;

export const CellBlockField = styled.div`
    display: flex;

    &:last-child {
        ${CellBlockProperty} {
            margin: 0 ${space.xxSmall} 0 0;
        }
    }
`;

export const CellBlockValue = styled.p<{
    color?: string;
}>`
    color: ${({ color }) => (color ? color : colors.grayDarker)};
    margin: 0 0 ${space.xSmall} 0;

    &:last-child {
        margin: 0;
    }
`;

export const ChangesText = styled.p`
    color: ${colors.grayDark};
    margin: 0;
    font-size: ${fontSize.xSmall};
    align-self: center;
    margin-left: auto;
`;

export default CellBlock;
