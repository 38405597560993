import {
    createColumnHelper,
    getCoreRowModel,
    getSortedRowModel,
    useReactTable,
} from "@tanstack/react-table";
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Button from "../../components/Button";
import Container from "../../components/Container";
import EmptyState from "../../components/EmptyState";
import Loader from "../../components/Loader";
import Plate from "../../components/Plate";
import SubHeader from "../../components/SubHeader";
import Table from "../../components/Table/Table";
import { useUnitListingDataQuery } from "../../queries/units/useUnitListingDataQuery";
import UnitTimeFramesListing from "./UnitTimeFramesListing";
import { UnitListingItem } from "./unitTypes";

const columnHelper = createColumnHelper<UnitListingItem>();
const columns = [
    columnHelper.accessor((row) => row.data.name, {
        id: "name",
        header: () => "Namn",
        cell: (props) => props.getValue(),
        meta: {
            getCellContext: () => {
                return {
                    length: 4,
                };
            },
        },
    }),
    columnHelper.accessor((row) => row.data.contact?.line1, {
        id: "address",
        header: () => "Adress",
        cell: (props) => props.getValue(),

        meta: {
            getCellContext: () => {
                return {
                    length: 4,
                };
            },
        },
    }),
    columnHelper.accessor((row) => row.data.contact?.phone, {
        id: "phone",
        enableSorting: false,
        header: () => "Telefon",
        cell: (props) => props.getValue(),
        meta: {
            getCellContext: () => {
                return {
                    fixedWidth: 150,
                };
            },
        },
    }),
    columnHelper.accessor((row) => row, {
        id: "restrictions",
        header: () => "Tidsfönster",
        enableSorting: false,
        cell: (props) => {
            const cellValue = props.getValue();
            if (cellValue.restrictions?.isPending) {
                return null;
            }

            if (
                cellValue.restrictions &&
                !cellValue.restrictions.isPending &&
                !cellValue.restrictions.data.length
            ) {
                return "-";
            }

            return (
                <UnitTimeFramesListing
                    minified
                    restrictions={cellValue?.restrictions?.data}
                    hasNotifications={!!cellValue?.replacements?.data.length}
                />
            );
        },
        meta: {
            getCellContext: () => {
                return {
                    fixedWidth: 350,
                };
            },
        },
    }),
];

const UnitListingView = () => {
    const { pathname } = useLocation();
    const navigate = useNavigate();

    const { isLoading, data } = useUnitListingDataQuery();

    const table = useReactTable({
        data: data || [],
        columns: columns,
        getCoreRowModel: getCoreRowModel(),
        getSortedRowModel: getSortedRowModel(),
    });

    return (
        <Container>
            <SubHeader
                title="Enheter"
                rightContent={
                    <Button
                        size="small"
                        onClick={() => {
                            navigate(pathname + "/skapa-ny");
                        }}
                        title="Skapa ny"
                    />
                }
            />
            <Plate spaceBottom={true} overScroll>
                {isLoading ? (
                    <Loader />
                ) : !data?.length ? (
                    <EmptyState text="Det finns inga enheter." />
                ) : (
                    <Table
                        table={table}
                        getLinkTo={(data) => pathname + "/" + data.data.code}
                    />
                )}
            </Plate>
        </Container>
    );
};

export default UnitListingView;
