import { DevTool } from "@hookform/devtools";
import React from "react";
import { Controller, UseFormReturn } from "react-hook-form";
import { getValidationResultFromFormError } from "../../common/validations";
import { FormField, FormFieldSet } from "../../components/FormField";
import TextInput from "../../components/TextInput";
import { CustomerFormData } from "./CustomerView.hooks";

interface Props {
    form: UseFormReturn<CustomerFormData>;
    enableFormDevTool: boolean;
}

const CustomerInfoForm = (props: Props) => {
    return (
        <>
            <FormFieldSet>
                <FormField halfSize>
                    <Controller
                        name="firstName"
                        control={props.form.control}
                        render={({
                            field: { onBlur, onChange, value },
                            fieldState,
                        }) => (
                            <TextInput
                                value={value}
                                onChange={onChange}
                                onBlur={onBlur}
                                autoComplete="on"
                                placeholder="Förnamn"
                                required
                                validation={getValidationResultFromFormError(
                                    fieldState
                                )}
                            />
                        )}
                    />
                </FormField>
                <FormField halfSize>
                    <Controller
                        name="lastName"
                        control={props.form.control}
                        render={({
                            field: { onBlur, onChange, value },
                            fieldState,
                        }) => (
                            <TextInput
                                value={value}
                                onChange={onChange}
                                onBlur={onBlur}
                                autoComplete="on"
                                placeholder="Efternamn"
                                required
                                validation={getValidationResultFromFormError(
                                    fieldState
                                )}
                            />
                        )}
                    />
                </FormField>
            </FormFieldSet>
            <FormField>
                <Controller
                    name="line1"
                    control={props.form.control}
                    render={({
                        field: { onBlur, onChange, value },
                        fieldState,
                    }) => (
                        <TextInput
                            value={value}
                            onChange={onChange}
                            onBlur={onBlur}
                            autoComplete="on"
                            required
                            placeholder="Gatuadress"
                            validation={getValidationResultFromFormError(
                                fieldState
                            )}
                        />
                    )}
                />
            </FormField>
            <FormFieldSet>
                <FormField halfSize>
                    <Controller
                        name="postalCode"
                        control={props.form.control}
                        render={({
                            field: { onBlur, onChange, value },
                            fieldState,
                        }) => (
                            <TextInput
                                value={value}
                                onChange={onChange}
                                onBlur={onBlur}
                                autoComplete="on"
                                required
                                type="number"
                                placeholder="Postnummer"
                                validation={getValidationResultFromFormError(
                                    fieldState
                                )}
                            />
                        )}
                    />
                </FormField>
                <FormField halfSize>
                    <Controller
                        name="town"
                        control={props.form.control}
                        render={({
                            field: { onBlur, onChange, value },
                            fieldState,
                        }) => (
                            <TextInput
                                value={value}
                                onChange={onChange}
                                onBlur={onBlur}
                                autoComplete="on"
                                required
                                placeholder="Ort"
                                validation={getValidationResultFromFormError(
                                    fieldState
                                )}
                            />
                        )}
                    />
                </FormField>
            </FormFieldSet>
            <FormFieldSet>
                <FormField halfSize>
                    <Controller
                        name="phone"
                        control={props.form.control}
                        render={({
                            field: { onBlur, onChange, value },
                            fieldState,
                        }) => (
                            <TextInput
                                value={value}
                                onChange={onChange}
                                onBlur={onBlur}
                                autoComplete="on"
                                type="tel"
                                placeholder="Mobilnummer"
                                validation={getValidationResultFromFormError(
                                    fieldState
                                )}
                            />
                        )}
                    />
                </FormField>
                <FormField halfSize>
                    <Controller
                        name="homePhone"
                        control={props.form.control}
                        render={({
                            field: { onBlur, onChange, value },
                            fieldState,
                        }) => (
                            <TextInput
                                value={value}
                                onChange={onChange}
                                onBlur={onBlur}
                                autoComplete="on"
                                type="tel"
                                placeholder="Telefonnummer"
                                validation={getValidationResultFromFormError(
                                    fieldState
                                )}
                            />
                        )}
                    />
                </FormField>
            </FormFieldSet>
            <FormFieldSet>
                <FormField halfSize>
                    <Controller
                        name="doorCode"
                        control={props.form.control}
                        render={({
                            field: { onBlur, onChange, value },
                            fieldState,
                        }) => (
                            <TextInput
                                value={value}
                                onChange={onChange}
                                onBlur={onBlur}
                                autoComplete="on"
                                type="tel"
                                placeholder="Portkod"
                                validation={getValidationResultFromFormError(
                                    fieldState
                                )}
                            />
                        )}
                    />
                </FormField>
                <FormField halfSize>
                    <Controller
                        name="email"
                        control={props.form.control}
                        render={({
                            field: { onBlur, onChange, value },
                            fieldState,
                        }) => (
                            <TextInput
                                value={value}
                                onChange={onChange}
                                onBlur={onBlur}
                                autoComplete="on"
                                type="text"
                                placeholder="E-postadress"
                                validation={getValidationResultFromFormError(
                                    fieldState
                                )}
                            />
                        )}
                    />
                </FormField>
            </FormFieldSet>
            <FormField>
                <Controller
                    name="orderPickerMessage"
                    control={props.form.control}
                    render={({
                        field: { onBlur, onChange, value },
                        fieldState,
                    }) => (
                        <TextInput
                            value={value}
                            onChange={onChange}
                            onBlur={onBlur}
                            autoComplete="on"
                            multiline
                            placeholder="Kommentar till beställare och plockare"
                            validation={getValidationResultFromFormError(
                                fieldState
                            )}
                        />
                    )}
                />
            </FormField>
            {props.enableFormDevTool && (
                <DevTool control={props.form.control} />
            )}
        </>
    );
};

export default CustomerInfoForm;
