import { useLayoutEffect, useRef } from "react";

export const useSavedInRef = <T>(value: T) => {
    const ref = useRef<T>(value);

    useLayoutEffect(() => {
        ref.current = value;
    });

    return ref;
};
