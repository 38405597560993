import { zodResolver } from "@hookform/resolvers/zod";
import { DefaultValues, useForm } from "react-hook-form";
import { z } from "zod";
import { formatTimeRestrictionDate } from "../../common/helpers/formatTimeRestrictionDate";
import { ssnRequiredMessage } from "../../common/zodSchemas";
import {
    isCustomerPaymentInvoice,
    isInvoicePayment,
} from "./customerDetailsConfig";
import {
    createCustomerFormSchema,
    editCustomerFormSchema,
} from "./customerFormSchema";
import { Customer } from "./customerTypes";

export type CustomerFormData =
    | z.infer<typeof createCustomerFormSchema>
    | z.infer<typeof editCustomerFormSchema>;

export const getCreateCustomerSchema = () => createCustomerFormSchema;

export const getEditCustomerFormSchema = (lastSavedDetails: Customer) =>
    editCustomerFormSchema.superRefine(
        editSsnConditionallyRequiredRefinement(lastSavedDetails)
    );

const editShouldSsnBeRequired = (lastSavedDetails: Customer) => {
    // const invoicePayment = isInvoicePayment(formDataPaymentName);
    // const isOldNotSsnUser =
    //     isInvoicePayment(lastSavedDataPaymentName) && !lastSavedSsn;
    // const isNewInvoiceUser =
    //     !isInvoicePayment(lastSavedDataPaymentName) && invoicePayment;
    // const ssnInactive = !(
    //     isNewInvoiceUser ||
    //     (invoicePayment && !isOldNotSsnUser)
    // );
    // if ((isInvoice && hadSsnPreviously) || (!wasInvoice && isInvoice)) {
    //     customerFormValidations.socialSecurityNumber = [isSSN, isRequired];
    // } else {
    //     customerFormValidations.socialSecurityNumber = [isSSN];
    // }

    const hadSsnPreviously = !!lastSavedDetails.socialSecurityNumber;
    const wasInvoice = isInvoicePayment(lastSavedDetails.paymentInfo?.id);

    const shouldBeRequired = hadSsnPreviously || !wasInvoice;

    return shouldBeRequired;
};

const editSsnConditionallyRequiredRefinement =
    (lastSavedDetails: Customer) =>
    (data: CustomerFormData, ctx: z.RefinementCtx) => {
        const shouldBeRequired = editShouldSsnBeRequired(lastSavedDetails);

        if (
            shouldBeRequired &&
            isCustomerPaymentInvoice(data) &&
            !data.socialSecurityNumber
        ) {
            ctx.addIssue({
                path: ["socialSecurityNumber"],
                message: ssnRequiredMessage,
                code: z.ZodIssueCode.custom,
            });
        }
    };

export const mapCustomerToDefaultFormValues = (
    customer?: Customer
): DefaultValues<CustomerFormData> => {
    return {
        firstName: customer?.firstName || "",
        lastName: customer?.lastName || "",
        line1: customer?.line1 || "",
        postalCode: customer?.postalCode || "",
        town: customer?.town || "",
        phone: customer?.phone || "",
        homePhone: customer?.homePhone || "",
        doorCode: customer?.doorCode || "",
        email: customer?.email || undefined,
        orderPickerMessage: customer?.orderPickerMessage || "",
        unit: customer?.unit && {
            id: customer?.unit.id,
            name: customer?.unit.name,
        },
        timeRestrictions:
            customer?.timeRestrictions?.map((restriction) => ({
                id: formatTimeRestrictionDate(restriction).value,
                name: formatTimeRestrictionDate(restriction).value,
                extraData: restriction,
            })) || [],

        defaultDriverMessage: customer?.defaultDriverMessage || "",
        medmeraId: customer?.medmeraId || undefined,
        paymentInfoId: customer?.paymentInfo?.id || undefined,
        billing: customer?.billing
            ? {
                  billingFirstName: customer.billing.billingFirstName || "",
                  billingLastName: customer.billing.billingLastName || "",
                  billingLine1: customer.billing.billingLine1 || "",
                  billingLine2: customer.billing.billingLine2 || "",
                  billingPostalCode: customer.billing.billingPostalCode || "",
                  billingTown: customer.billing.billingTown || "",
                  billingPhone: customer.billing.billingPhone || "",
                  billingReference: customer.billing.billingReference || "",
                  billingEmail: customer.billing.billingEmail || undefined,
                  emailInvoice: customer.billing.emailInvoice || false,
              }
            : {
                  emailInvoice: false,
              },
        registrationEmail: customer?.registrationEmail || undefined,
        helpToUnpack: customer?.helpToUnpack || false,
        selfOrder: customer?.selfOrder || false,
        socialSecurityNumber: customer?.socialSecurityNumber || undefined,
        hasTransactionalEmail: !!customer?.transactionalEmails,
        transactionalEmail:
            (customer &&
                customer.transactionalEmails?.map((item) => ({
                    value: item,
                }))) ||
            (customer &&
                customer.selfOrder && // is self orderer, prefill with email that user uses to login as self orderer
                customer.registrationEmail && [
                    { value: customer.registrationEmail },
                ]) ||
            undefined,
    };
};

export const useEditCustomerForm = (customer: Customer) => {
    const form = useForm<CustomerFormData>({
        resolver: zodResolver(getEditCustomerFormSchema(customer)),
        mode: "onTouched",
        defaultValues: customer && mapCustomerToDefaultFormValues(customer),
    });

    return form;
};

export const useCreateCustomerForm = () => {
    const form = useForm<CustomerFormData>({
        resolver: zodResolver(getCreateCustomerSchema()),
        mode: "onTouched",
        defaultValues: mapCustomerToDefaultFormValues(undefined),
    });

    return form;
};
