import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useContext } from "react";
import { ordererMethods } from "../api/apiMethods";
import { UserContext } from "../contexts/UserContext";
import { queryKeys } from "../queries/queryKeys";
import { Logger } from "../utils/logger/Logger";
import { OrdererInput } from "../views/OrdererView/ordererTypes";

export const useCreateOrdererMutation = (options: {
    onSuccess: (createdOrdererId: string) => void;
    onError: (error: any) => void;
}) => {
    const { authUserData } = useContext(UserContext);
    const queryClient = useQueryClient();

    const mutation = useMutation({
        mutationFn: async (input: OrdererInput) => {
            if (!authUserData) {
                throw new Error("No auth user data");
            }

            let ordererId: string | undefined;

            try {
                ordererId = (
                    await ordererMethods(
                        authUserData.municipalityCode
                    ).createOrderer(input)
                ).ordererID;

                if (!ordererId) {
                    throw new Error(
                        "Could not create a user. No user id was returned"
                    );
                }
            } catch (err) {
                throw err;
            }

            return ordererId;
        },
        onSuccess: options.onSuccess,
        onError: async (error: Response) => {
            Logger.error(error);

            let parsedError;
            try {
                parsedError = await error.json();
            } catch {}

            options.onError(parsedError);
        },
        onSettled: () => {
            queryClient.invalidateQueries({ queryKey: queryKeys.orderer.all });
        },
    });

    return mutation;
};
