import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useContext } from "react";
import { ordererMethods } from "../api/apiMethods";
import { UserContext } from "../contexts/UserContext";
import { queryKeys } from "../queries/queryKeys";
import { Logger } from "../utils/logger/Logger";
import { OrdererInput } from "../views/OrdererView/ordererTypes";

export const useUpdateOrdererMutation = (options: {
    onSuccess: () => void;
    onError: (error: any) => void;
}) => {
    const queryClient = useQueryClient();

    const { authUserData } = useContext(UserContext);

    const mutation = useMutation({
        mutationFn: async (data: {
            ordererId?: string;
            input: OrdererInput;
        }) => {
            if (!authUserData) {
                throw new Error("No auth user data");
            }

            if (!data.ordererId) {
                throw new Error("No orderer id");
            }

            try {
                return ordererMethods(
                    authUserData.municipalityCode
                ).updateOrderer(data.input, data.ordererId);
            } catch (error) {
                throw error;
            }
        },
        onSuccess: options.onSuccess,
        onError: async (error: Response) => {
            Logger.error(error);

            let parsedError;
            try {
                parsedError = await error.json();
            } catch {}

            options.onError(parsedError);
        },
        onSettled: () => {
            queryClient.invalidateQueries({ queryKey: queryKeys.orderer.all });
        },
    });

    return mutation;
};
