import { z } from "zod";
import { maxLengthInvalidMessage } from "../../common/validations";
import {
    addressLineSchema,
    emailSchema,
    firstNameSchema,
    lastNameSchema,
    mobilePhoneRequiredSchema,
    postalCodeSchema,
    townSchema,
} from "../../common/zodSchemas";

const emailInvoiceUnion = z
    .object({
        emailInvoice: z.boolean().default(false),
    })
    .and(
        z.discriminatedUnion("emailInvoice", [
            z.object({
                emailInvoice: z.literal(true),
                billingEmail: emailSchema,
            }),
            z.object({ emailInvoice: z.literal(false) }),
        ])
    );

export const customerBillingSchema = z
    .object({
        billingFirstName: firstNameSchema,
        billingLastName: lastNameSchema,
        billingLine1: addressLineSchema,
        billingLine2: z.string().optional(),
        billingPostalCode: postalCodeSchema,
        billingTown: townSchema,
        billingPhone: mobilePhoneRequiredSchema,
        billingReference: z
            .string()
            .max(60, { message: maxLengthInvalidMessage(60) })
            .optional(),
    })
    .and(emailInvoiceUnion);
