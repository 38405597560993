import React, { useContext, useEffect, useRef } from "react";
import { animated, useSpring } from "react-spring";
import styled, { css } from "styled-components";
import colors from "../common/colors";
import useOutsideClick from "../common/helpers/useOutsideClick";
import mixins from "../common/mixins";
import { dimension, fontSize, space } from "../common/variables";

interface ModalContextType {
    setModalOptions: React.Dispatch<React.SetStateAction<ModalOptions>>;
    modalOptions: ModalOptions;
}

export const ModalContext = React.createContext<ModalContextType>({
    setModalOptions: () => null,
    modalOptions: { visible: false },
});

export interface ModalOptions {
    title?: string;
    subtitle?: string;
    visible: boolean;
    buttons?: React.ReactNode[];
    dismissTimerMs?: number;
    lockDismiss?: boolean;
    onDismiss?: () => void;
}

const Modal = ({
    title,
    subtitle,
    buttons,
    visible,
    dismissTimerMs,
    onDismiss,
}: ModalOptions) => {
    const contentRef = useRef<HTMLDivElement & any>();
    const { modalOptions, setModalOptions } = useContext(ModalContext);

    const animProps = useSpring({
        transform: `scale(${visible ? 1 : 0.9}) translateY(${
            visible ? 0 : -20
        }px)`,
        config: { mass: 0.6, tension: 90, friction: 15 },
    });

    useEffect(() => {
        if (!dismissTimerMs) {
            return;
        }
        setTimeout(() => {
            setModalOptions({ visible: false });
        }, dismissTimerMs);
    }, [dismissTimerMs, setModalOptions]);

    useOutsideClick(contentRef, () => {
        if (visible && !modalOptions.lockDismiss) {
            setModalOptions({
                visible: false,
            });
            onDismiss?.();
        }
    });

    return (
        <Wrapper visible={visible}>
            <AnimatedDiv style={animProps}>
                <Content ref={contentRef}>
                    {/* <Close onClick={() => dismissModal()}>{assets.cross}</Close> */}
                    {title && <Title>{title}</Title>}
                    {subtitle && <Subtitle>{subtitle}</Subtitle>}
                    <ButtonContainer>
                        {buttons?.map((button, i) => (
                            <ButtonContent key={i}>{button}</ButtonContent>
                        ))}
                    </ButtonContainer>
                </Content>
            </AnimatedDiv>
        </Wrapper>
    );
};

export default Modal;

const Wrapper = styled.div`
    width: 100%;
    height: 100%;
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    left: 0;
    overflow: hidden;
    background-color: rgba(0, 0, 0, 0.5);
    transition: opacity ${mixins.transitions.default};
    z-index: 9;

    ${({ visible }: { visible: boolean }) =>
        visible
            ? css``
            : css`
                  pointer-events: none;
                  opacity: 0;
              `};
`;

const AnimatedDiv = styled(animated.div)`
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 ${space.paddingSmall};
`;

const Content = styled.div`
    background-color: ${colors.white};
    width: 100%;
    min-height: 220px;
    max-width: ${dimension.containerSmall};
    padding: ${space.xxLarge};
    text-align: center;
    position: relative;
    border-radius: ${mixins.borderRadius.default};
    display: flex;
    flex-direction: column;
    justify-content: center;
`;

const Title = styled.h3`
    margin: 0;
`;

const Subtitle = styled.p`
    margin-top: ${space.medium};
    margin-bottom: 0;
    color: ${colors.grayDarker};
    line-height: ${fontSize.xLarge};
`;

const ButtonContainer = styled.div`
    display: flex;
    margin-top: ${space.xLarge};
    justify-content: center;
`;

const ButtonContent = styled.div`
    margin: 0 ${space.xSmall};
`;
