import React from "react";
import Button from "../components/Button";

/**
 * This code is in fact returned by IS on Hybris side, when error happens Hybris doesnt wrap it in their own error handling model, but forward unmodified IS error to the frontend
 * */
export const CUSTOMER_DUPLICATE_EMAIL_MESSAGE = "DuplicateEmail";
export const ORDERER_DUPLICATE_EMAIL_MESSAGE =
    "DuplicateEmailConversionNotAllowed";
export const SSN_MMID_MISSMATCH = "medmeraId.ssn.inconsistency";

export const genericErrorConfig = (
    callback: () => void,
    title?: string,
    subtitle?: string
) => ({
    visible: true,
    title: title?.length ? title : "Någonting gick snett.",
    subtitle: subtitle?.length
        ? subtitle
        : "Vänligen ladda om sidan eller försök igen senare.",
    buttons: [<Button size="small" title="Jag förstår" onClick={callback} />],
});

export const genericFormErrorConfig = (
    callback: () => void,
    title?: string,
    subtitle?: string
) => ({
    visible: true,
    title: title?.length ? title : "Någonting gick snett.",
    subtitle: subtitle?.length
        ? subtitle
        : "Vänligen kontrollera uppgifterna i formuläret.",
    buttons: [<Button size="small" title="Jag förstår" onClick={callback} />],
});
