import styled, { css } from "styled-components";
import colors from "../../common/colors";
import { fontSize, space } from "../../common/variables";

const Wrapper = styled.div`
    display: flex;
    height: 70px;
    justify-content: space-between;

    gap: var(--tableColumnGap);
    padding: 0 var(--tableColumnGap);
`;
const Cell = styled.div<{
    growWidth?: boolean;
    headerLength?: number;
    fixedWidth?: number;
}>`
    display: flex;
    flex-shrink: 0;
    flex-grow: ${({ growWidth }) => (growWidth ? 1 : 0)};
    align-items: center;
    width: ${({ headerLength, fixedWidth }) => {
        if (fixedWidth !== undefined) {
            return `${fixedWidth}px;`;
        } else {
            if (headerLength !== undefined)
                return `calc(${100 / headerLength}% - var(--tableColumnGap));`;
        }
        return undefined;
    }};
`;

const TitleBase = css`
    font-size: ${fontSize.small};
    padding: ${space.small} ${space.small} ${space.small} 0;
    line-height: ${fontSize.medium};
    text-overflow: ellipsis;
    overflow: hidden;
    position: relative;
    color: ${colors.black};
    fill: ${colors.black};
    font-weight: 700;
`;

const Title = styled.div`
    ${TitleBase};
`;

const TitleClickable = styled.button`
    ${TitleBase};
    cursor: pointer;
    transition: color 0.15s, fill 0.15s;
    &:hover {
        color: ${colors.green};
        fill: ${colors.green};
    }
`;

const Icon = styled.span`
    position: absolute;
    height: 12px;
    width: 9px;
    pointer-events: none;
    margin-top: -1px;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    opacity: ${({ visible }: { visible?: boolean }) => (visible ? 1 : 0)};
`;

const IconContainer = styled.span`
    display: block;
    height: 100%;
    width: 100%;
    transition: transform 0.15s;

    ${({ sorting }: { sorting?: "asc" | "desc" }) => {
        if (sorting === "asc") {
            return css`
                transform: rotate(-90deg) scale(1);
            `;
        } else if (sorting === "desc") {
            return css`
                transform: rotate(90deg) scale(1);
            `;
        } else {
            return css`
                transform: rotate(-90deg) scale(0);
            `;
        }
    }};
`;

export const TableHeaderStyles = {
    Wrapper,
    Cell,
    Title,
    TitleClickable,
    Icon,
    IconContainer,
};
