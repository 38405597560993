import React, { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { animated, useTransition } from "react-spring";
import styled from "styled-components";
import { municipalityMethods, userMethods } from "../api/apiMethods";
import { appConfig } from "../appConfig";
import { identityConfig, pathBase } from "../auth/authConst";
import { AuthContext } from "../auth/authProvider";
import { genericErrorConfig } from "../common/exceptions";
import { MatkontoTmpInfoProvider } from "../common/helpers/matkontoTmpInfoProider";
import mixins from "../common/mixins";
import { ListOption } from "../common/types";
import Header from "../components/Header";
import Main from "../components/Main";
import Modal, { ModalContext, ModalOptions } from "../components/Modal";
import { CustomerFilterContext } from "../contexts/CustomerFilterContext";
import { AuthContextObject, UserContext } from "../contexts/UserContext";
import { Logger } from "../utils/logger/Logger";
import AnimatedOutlet from "./AnimatedOutlet";
import Authorized from "./Authorized";
import { matkontoCallbackPath } from "./CalbackViews/MatkontoCallbackView";
import { navigationItems } from "./navigationItems";

const Layout = () => {
    const location = useLocation();
    const { signout } = useContext(AuthContext);
    const [authUserData, setAuthUserData] = useState<AuthContextObject>();
    const [customerFilter, setCustomerFilter] = useState<ListOption[]>([]);
    const [modalOptions, setModalOptions] = useState<ModalOptions>({
        visible: false,
    });

    const transitions = useTransition(location, {
        initial: { opacity: 0, transform: "translate3d(1%, 0, 0)" },
        from: { opacity: 0, transform: "translate3d(1%, 0, 0)" },
        enter: {
            opacity: 1,
            transform: "translate3d(0, 0, 0)",
            animationDelay: "150",
            delay: 150,
        },
        leave: {
            opacity: 0,
            transform: "translate3d(-1%, 0, 0)",
            animationDuration: "145",
            config: { duration: 145 },
        },
    });

    useEffect(() => {
        window.scroll({
            top: 0,
            left: 0,
        });
    }, [location]);

    useEffect(() => {
        const session = `oidc.user:${identityConfig.authority}:${identityConfig.client_id}`;
        const storageObj = sessionStorage.getItem(session);

        if (!storageObj) {
            return;
        }

        const userId = JSON.parse(storageObj)["profile"]["sub"] as string;
        userMethods()
            .getUser(userId)
            .then((res) => {
                if (!res.municipalityCode) {
                    setAuthUserData({
                        ...res,
                        identityId: userId,
                    });
                } else {
                    municipalityMethods(res.municipalityCode)
                        .getMunicipality()
                        .then((municipality) => {
                            setAuthUserData({
                                ...res,
                                identityId: userId,
                                municipalityName: municipality.name,
                            });
                        })
                        .catch((error) => {
                            Logger.error(error);
                            setAuthUserData({
                                ...res,
                                identityId: userId,
                            });
                        });
                }
            })
            .catch((error) => {
                Logger.error({ userId, error });
                setModalOptions(
                    genericErrorConfig(
                        () => {
                            setModalOptions({ visible: false });
                            signout();
                        },
                        "Din inloggningssession har löpt ut.",
                        "Vi skickar dig till inloggningssidan."
                    )
                );
            });
    }, [signout]);

    useEffect(() => {
        if (!authUserData) {
            return;
        }

        const userId = authUserData.identityId;
        const municipalityCode = authUserData.municipalityCode;

        const storageFilterKey = `${appConfig.MODE}.${userId}.${municipalityCode}.filter`;

        const filterIds = localStorage
            .getItem(storageFilterKey)
            ?.split(",")
            .map((el) => {
                return { id: el };
            }) as ListOption<string, string, any>[];

        if (filterIds && !!filterIds.length) {
            setCustomerFilter(filterIds);
        }
    }, [authUserData]);

    useEffect(() => {
        if (!authUserData) {
            return;
        }

        const userId = authUserData.identityId;
        const municipalityCode = authUserData.municipalityCode;

        const storageFilterKey = `${appConfig.MODE}.${userId}.${municipalityCode}.filter`;
        const storageFilterValue = customerFilter.map((el) => el.id).join(",");

        if (!!customerFilter.length) {
            localStorage.setItem(storageFilterKey, storageFilterValue);
        } else {
            localStorage.removeItem(storageFilterKey);
        }
    }, [customerFilter]);

    useMatkontoCookieGuard();

    return (
        <UserContext.Provider
            value={{
                authUserData,
                setAuthUserData,
            }}>
            <ModalContext.Provider value={{ modalOptions, setModalOptions }}>
                <CustomerFilterContext.Provider
                    value={{
                        customerFilter,
                        setCustomerFilter,
                    }}>
                    <Main>
                        <Header
                            blur={modalOptions.visible}
                            navigation={
                                authUserData?.municipalityRole === "ADMIN"
                                    ? [...navigationItems]
                                    : [navigationItems[0]]
                            }
                        />
                        <Authorized>
                            {transitions((style, item, t) => (
                                <Content
                                    style={style}
                                    blur={modalOptions.visible ? 1 : 0}
                                    key={item.pathname}>
                                    <AnimatedOutlet />
                                </Content>
                            ))}
                        </Authorized>
                    </Main>
                    <Modal {...modalOptions} />
                </CustomerFilterContext.Provider>
            </ModalContext.Provider>
        </UserContext.Provider>
    );
};

export default Layout;

export const Content = styled(animated.div)<{ blur?: number }>`
    position: absolute;
    width: 100%;
    transition: filter ${mixins.transitions.large};
    filter: ${({ blur }) => (blur ? "blur(4px)" : "none")};
`;

const useMatkontoCookieGuard = () => {
    const location = useLocation();

    // the only locations that can have the tmp values are base path (before firing react router), and Matkonto callback url
    const locationIsAllowed = () => {
        return (
            location.pathname.includes(matkontoCallbackPath) ||
            location.pathname === pathBase
        );
    };

    if (MatkontoTmpInfoProvider.exists() && !locationIsAllowed()) {
        MatkontoTmpInfoProvider.remove();
    }
};
