import React, { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { pathBase, pathSignIn } from "../auth/authConst";
import { AuthContext } from "../auth/authProvider";
import Loader from "../components/Loader";

const AuthCallbackView = () => {
    const { signinRedirectCallback } = useContext(AuthContext);
    const navigate = useNavigate();

    useEffect(() => {
        signinRedirectCallback()
            .then(() => {
                navigate(pathBase, { replace: true });
            })
            .catch(() => {
                navigate(pathSignIn, { replace: true });
            });
    }, [navigate, signinRedirectCallback]);

    return <Loader fullHeight />;
};

export default AuthCallbackView;
