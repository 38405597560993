import React, { useEffect, useState } from "react";
import styled, { keyframes, css } from "styled-components";
import colors from "../common/colors";
import { space, fontSize } from "../common/variables";
import mixins from "../common/mixins";

interface Props {
    fullHeight?: boolean;
    small?: boolean;
    noMinHeight?: boolean;
    timeoutText?: string;
    color?: string;
}

const Loader = (props: Props) => {
    const [showText, setShowText] = useState(false);
    useEffect(() => {
        const timeout = setTimeout(() => {
            setShowText(true);
        }, 5000);

        return () => {
            clearTimeout(timeout);
        };
    }, []);

    return (
        <Wrapper fullHeight={props.fullHeight} noMinHeight={props.noMinHeight}>
            <Icon small={props.small}>
                <Ball small={props.small} color={props.color} />
                <Ball small={props.small} color={props.color} />
                <Ball small={props.small} color={props.color} />
            </Icon>
            <Text visible={showText}>
                {props.timeoutText?.length
                    ? props.timeoutText
                    : "Ta en kaffe medan du väntar."}
            </Text>
        </Wrapper>
    );
};

export default Loader;

const Wrapper = styled.div<{
    noMinHeight?: boolean;
    fullHeight?: boolean;
}>`
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: ${({ noMinHeight }) => (noMinHeight ? "100%" : "210px")};
    height: ${({ fullHeight }) => (fullHeight ? "80vh" : "auto")};
`;

const Icon = styled.div`
    width: ${({ small }: { small?: boolean }) => (small ? "50px" : "70px")};
    display: flex;
    justify-content: space-between;
`;

const animationKeyframe = keyframes`
    0%, 100%, 80% {
        transform: scale(0);
    }
    40% {
        transform: scale(1);
    }
`;

const Ball = styled.div<{
    small?: boolean;
    color?: string;
}>`
    ${({ small }) =>
        small
            ? css`
                  width: 12px;
                  height: 12px;
              `
            : css`
                  width: 18px;
                  height: 18px;
              `};
    background-color: ${({ color }: { color?: string }) =>
        color ? color : colors.green};
    border-radius: 50%;
    display: inline-block;
    animation: ${animationKeyframe} 1.4s infinite ease-in-out both;

    &:nth-child(1) {
        animation-delay: -0.32s;
    }

    &:nth-child(2) {
        animation-delay: -0.16s;
    }
`;

const Text = styled.p`
    position: absolute;
    bottom: ${space.padding};
    font-size: ${fontSize.small};
    color: ${colors.grayDark};
    transition: opacity ${mixins.transitions.default};
    opacity: ${({ visible }: { visible: boolean }) => (visible ? 1 : 0)};
`;
