import { CustomerBillingDTO, CustomerDTO } from "../../api/apiTypes";
import { PaymentTypes } from "../../common/translations";
import { ReplacementWindowGroup, TimeRestriction } from "../../common/types";

/** Domain model for Customer entity */
export interface Customer {
    id: string;
    firstName: string;
    lastName: string;
    line1: string;
    postalCode: string;
    town: string;
    phone: string;
    homePhone: string;
    orderPickerMessage: string;
    defaultDriverMessage: string;
    helpToUnpack: boolean;
    selfOrder: boolean;
    doorCode: string;
    email: string;
    socialSecurityNumber: string;
    medmeraId: string;
    billing?: CustomerBilling;
    timeRestrictions?: TimeRestriction[];
    paymentInfo?: CustomerPaymentInfo;
    unit?: {
        id: string;
        name: string;
    };
    registrationEmail?: string;
    transactionalEmails?: string[];
}

interface CustomerMatkontoPaymentInfo {
    id: Extract<PaymentCodes, "matkonto">;
    name: Extract<PaymentTypes, "Matkonto">;
    paymentToken?: {
        id: string;
        cardBrand: string;
        expiryDate: string;
        maskedCardNumberNumber: string;
    };
}

interface CustomerOtherPaymentInfo {
    id: Exclude<PaymentCodes, "matkonto">;
    name: Exclude<PaymentTypes, "Matkonto">;
}

type CustomerPaymentInfo =
    | CustomerMatkontoPaymentInfo
    | CustomerOtherPaymentInfo;

export const isCustomerMatkontoPayment = (
    paymentInfo: CustomerPaymentInfo
): paymentInfo is CustomerMatkontoPaymentInfo => {
    return paymentInfo.id === "matkonto";
};

/**
 * Domain model for CustomerBilling entity
 */
export interface CustomerBilling {
    billingId: string;
    billingFirstName?: string;
    billingLastName?: string;
    billingLine1?: string;
    billingLine2?: string;
    billingPostalCode?: string;
    billingTown?: string;
    billingPhone?: string;
    billingReference?: string;
    billingEmail?: string;
    emailInvoice: boolean;
}

export interface CustomerListingOrder {
    code: string;
    isPartOfSubscription: boolean;
    created: string;
    status: string;
}

export type PaymentInfoCodes = "IntrumInvoicePaymentInfo" | "KobePaymentInfo";

export const PaymentCodesArray = [
    "intrum",
    "matkonto",
    "collectiveinvoice",
] as const;
export type PaymentCodes = (typeof PaymentCodesArray)[number];

export interface CustomerListingItem {
    data: CustomerDTO;
    billingAddress?: CustomerBillingDTO;
    orders: CustomerListingOrder[];
    restrictions: TimeRestriction[];
    replacements: ReplacementWindowGroup[] | undefined;
}
