import React from "react";
import Container from "../../components/Container";
import Plate from "../../components/Plate";
import SubHeader from "../../components/SubHeader";
import NewCustomerSettingsView from "./NewCustomerSettingsView";

const NewCustomerView = () => {
    return (
        <Container>
            <SubHeader backArrow title={"Skapa ny brukare"} />
            <Plate spaceBottom={true} padding={"large"}>
                <NewCustomerSettingsView />
            </Plate>
        </Container>
    );
};

export default NewCustomerView;
