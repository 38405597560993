import { formatTimeRestrictionDate } from "../../common/helpers/formatTimeRestrictionDate";
import { groupTimeRestrictions } from "../../common/helpers/groupTimeRestrictions";
import { DaysConsistentEnum } from "../../common/translations";
import { TimeRestriction } from "../../common/types";

export const compareTimeRestrictionsPredicate = (
    first: TimeRestriction,
    second: TimeRestriction
) =>
    first.dayOfWeek === second.dayOfWeek &&
    `${first.startTime}-${first.endTime}` ===
        `${second.startTime}-${second.endTime}`;

export const sortUnitsByRestrictions = (
    a: TimeRestriction[],
    b: TimeRestriction[]
) => {
    const aFormatted = a[0]
        ? formatTimeRestrictionDate({
              dayOfWeek:
                  DaysConsistentEnum[groupTimeRestrictions(a)[0].dayOfWeek],
              ...groupTimeRestrictions(a)[0].times[0],
          }).sortPredicate
        : "";

    const bFormatted = b[0]
        ? formatTimeRestrictionDate({
              dayOfWeek:
                  DaysConsistentEnum[groupTimeRestrictions(b)[0].dayOfWeek],
              ...groupTimeRestrictions(b)[0].times[0],
          }).sortPredicate
        : "";

    if (aFormatted === undefined && bFormatted !== undefined) {
        return -1;
    }

    if (aFormatted !== undefined && bFormatted === undefined) {
        return 1;
    }

    if (aFormatted === undefined && bFormatted === undefined) {
        return 0;
    }

    if (aFormatted !== undefined && bFormatted !== undefined) {
        return aFormatted.localeCompare(bFormatted);
    }

    throw new Error("Unknown sorting predicate");
};
