import React from "react";
import styled from "styled-components";
import assets from "../common/assets";
import { space } from "../common/variables";

interface Props {
    onClick?: () => void
}

const BackArrow = (props: Props) =>
    <Wrapper onClick={props.onClick}>
        { assets.angle }
    </Wrapper>

export default BackArrow

export const Wrapper = styled.span`
    height: 14px;
    width: 30px;
    display: inline-block;
    cursor: pointer;
    padding-right: ${space.small};
`;