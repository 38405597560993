import React from "react";
import styled from "styled-components";
import colors from "../../common/colors";
import { groupTimeRestrictions } from "../../common/helpers/groupTimeRestrictions";
import { daysConsistent } from "../../common/translations";
import { TimeRestriction } from "../../common/types";
import { fontSize, space } from "../../common/variables";
import { CellBlockTitle } from "../../components/Grid/CellBlock";
import Tag from "../../components/Tag";
import { Tooltip } from "../../components/Tooltip";

const UnitTimeFramesListing = ({
    restrictions,
    hasNotifications,
    minified,
}: {
    restrictions?: TimeRestriction[];
    hasNotifications?: boolean;
    minified?: boolean;
}) => {
    if (!restrictions?.length) {
        return null;
    }

    if (minified) {
        if (restrictions.length === 1) {
            const theRestriction = restrictions[0];
            return (
                <Tag hasNotification={hasNotifications}>
                    <div>
                        <Day minified={minified}>
                            {daysConsistent[
                                theRestriction.dayOfWeek
                            ].name.substr(0, 3)}
                        </Day>
                        <span>
                            <TimeMinified
                                startTime={theRestriction.startTime}
                                endTime={theRestriction.endTime}
                            />
                        </span>
                    </div>
                </Tag>
            );
        }

        return (
            <Tooltip
                content={
                    <TimeTooltipContent>
                        {restrictions.map((restriction, index) => (
                            <div key={index}>
                                <Day minified={false}>
                                    {daysConsistent[restriction.dayOfWeek].name}
                                </Day>
                                <span>
                                    <TimeMinified
                                        startTime={restriction.startTime}
                                        endTime={restriction.endTime}
                                    />
                                </span>
                            </div>
                        ))}
                    </TimeTooltipContent>
                }>
                <Tag
                    hasNotification={hasNotifications}
                    count={restrictions.length}
                    theme="white-green">
                    Valda tider
                </Tag>
            </Tooltip>
        );
    }

    return (
        <>
            {groupTimeRestrictions(restrictions).map((section, index) => (
                <CellBlockTitle key={index}>
                    <Tag>
                        <Day>{section.dayOfWeek}</Day>
                        <span>
                            {section.times.map((time, innerIndex) => (
                                <Time key={`${index}_${innerIndex}`}>
                                    {`${time.startTime}-${time.endTime}`}
                                </Time>
                            ))}
                        </span>
                    </Tag>
                </CellBlockTitle>
            ))}
        </>
    );
};

const TimeMinified = ({
    startTime,
    endTime,
}: {
    startTime: string;
    endTime: string;
}) => {
    return (
        <Time minified>
            {`${
                startTime.length < 5
                    ? "0" + startTime.substr(0, 1)
                    : startTime.substr(0, 2)
            }-${
                endTime.length < 5
                    ? "0" + endTime.substr(0, 1)
                    : endTime.substr(0, 2)
            }`}
        </Time>
    );
};

export default UnitTimeFramesListing;

const Day = styled.span<{ minified?: boolean }>`
    width: ${({ minified }) => (minified ? "25px" : "60px")};
    display: inline-block;
    font-weight: 700;
`;

const Time = styled.span<{ minified?: boolean }>`
    margin-left: ${space.xxSmall};
    color: ${colors.grayDarker};
    position: relative;
    font-weight: normal;

    &:not(:first-of-type) {
        &:before {
            content: "";
            position: absolute;
            height: 2px;
            width: 2px;
            border-radius: 1px;
            background-color: ${colors.grayDarker};
            top: 40%;
            left: -16%;
        }
    }
`;

const TimeTooltipContent = styled.div`
    font-size: ${fontSize.xSmall};
    display: flex;
    flex-direction: column;
    gap: 0px;

    & > * {
        display: block;
    }
`;
