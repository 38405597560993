import classNames from 'classnames';
import * as React from 'react';
import { PropsWithChildren, useEffect, useImperativeHandle, useRef } from 'react';

import styles from './Search.module.scss';
import { SearchSize, SearchTheme } from './Search.types';
import { getClassForSize, getClassForTheme } from './Search.utils';
import SearchContent from './SearchContent';

export interface SearchProps {
    useDropdown: boolean;
    inputText?: string;
    defaultValue?: string;
    inputPlaceholder: string;
    noBorderRadius?: boolean;
    noPanelOverflowHidden?: boolean;

    focusOnMount?: boolean;
    size?: SearchSize;
    hide?: boolean;
    theme?: SearchTheme;
    setInputText?: (newText: string) => void;
    onReset?: () => void;
    onInputClick?: () => void;
    onInputFocus?: () => void;
    onInputBlur?: () => void;
    onEnterPress?: () => void;
    onEscapePress?: () => void;
}

const Search = React.forwardRef<HTMLInputElement, PropsWithChildren<SearchProps>>(
    (props, inputRef) => {
        const localRef = useRef<HTMLInputElement>(null);

        useImperativeHandle(inputRef, () => localRef.current as HTMLInputElement);

        const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
            const { value } = e.currentTarget;
            props.setInputText?.(value);
        };

        const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
            if (e.key === 'Enter' && props.onEnterPress) {
                localRef.current?.blur();
                props.onEnterPress();
            }

            if (e.key === 'Escape' && props.onEscapePress) {
                localRef.current?.blur();
                props.onEscapePress();
            }
        };

        useEffect(() => {
            if (props.focusOnMount) {
                localRef.current?.focus();
            }
        }, [props.focusOnMount, localRef]);

        return (
            <div
                className={classNames(
                    styles.Search,
                    props.useDropdown &&
                        classNames(styles['Search--dropdown'], styles['is-active']),
                    props.noBorderRadius && styles['Search--noRadius'],
                    props.inputText && styles['has-value'],
                    getClassForTheme(props.theme),
                    props.hide && styles['Search--hidden'],
                    getClassForSize(props.size),
                )}
                data-test="search"
            >
                <SearchContent
                    ref={localRef}
                    inputPlaceholder={props.inputPlaceholder}
                    defaultValue={props.defaultValue}
                    onInputChange={handleInputChange}
                    inputText={props.inputText}
                    onInputFocus={props.onInputFocus}
                    onInputBlur={props.onInputBlur}
                    onKeyDown={handleKeyDown}
                    onInputClick={props.onInputClick}
                    onClearClick={props.onReset}
                    onSearchIconPress={props.onEnterPress}
                />
                {props.useDropdown && (
                    <div
                        className={classNames(
                            styles['Search-panel'],
                            props.noPanelOverflowHidden && styles['Search-panel--noOverflow'],
                        )}
                    >
                        {props.children}
                    </div>
                )}
            </div>
        );
    },
);

export default Search;
