import React from "react";
import { useNavigate } from "react-router-dom";
import styled, { keyframes } from "styled-components";
import mixins from "../common/mixins";
import { fontSize, space } from "../common/variables";
import BackArrow from "../components/BackArrow";

interface Props {
    backArrow?: boolean;
    title: string;
    leftContent?: React.ReactNode;
    rightContent?: React.ReactNode;
}

const SubHeader = (props: Props) => {
    const { backArrow, title, leftContent, rightContent } = props;
    const navigate = useNavigate();

    return (
        <Wrapper>
            <Nav
                clickable={backArrow}
                onClick={() => (backArrow ? navigate(-1) : null)}>
                {backArrow && <BackArrow />}
                <Title>{title}</Title>
            </Nav>
            <Content>
                <div>{leftContent}</div>
                <div>{rightContent}</div>
            </Content>
        </Wrapper>
    );
};

export default SubHeader;

const transIn = keyframes`
    0% {
        opacity: 0;
        transform: translateY(5px);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
`;

const Wrapper = styled.div`
    display: flex;
    height: 30px;
    align-items: center;
    margin-bottom: ${space.paddingSmall};
`;

const Nav = styled.button`
    display: flex;
    align-items: center;
    flex-shrink: 0;
    margin-right: ${space.xLarge};
    z-index: 1;
    animation: ${transIn} ${mixins.transitions.large} 0.1s forwards;
    opacity: 0;
    transform: translateY(5px);
    cursor: ${({ clickable }: { clickable?: boolean }) =>
        clickable ? "pointer" : "default"};
`;

const Content = styled.div`
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: space-between;
    z-index: 1;
    opacity: 0;
    transform: translateY(5px);
    animation: ${transIn} ${mixins.transitions.large}
        ${mixins.transitions.default} forwards;
`;

const Title = styled.h1`
    font-size: ${fontSize.large};
    margin: 0;
`;
