import React from "react";
import styled, { css } from "styled-components";
import { fontSize } from "../common/variables";
import colors from "../common/colors";

interface Props {
    href?: string;
    onClick?: () => void;
    color?: "green" | "gray";
    children: React.ReactNode;
}

const AnchorLink = (props: Props) => {
    return <Wrapper {...props}>{props.children}</Wrapper>;
};

const Wrapper = styled.a`
    font-size: ${fontSize.small};
    cursor: pointer;

    ${({ color }: { color?: "green" | "gray" }) =>
        color === "gray"
            ? css`
                  color: ${colors.grayDarker};

                  &:hover {
                      color: ${colors.black};
                  }
              `
            : css`
                  color: ${colors.green};

                  &:hover {
                      color: ${colors.greenDark};
                  }
              `}

    &:hover {
        color: ${colors.greenDark};
    }
`;

export default AnchorLink;
