import { daysConsistent } from "../translations";
import { TimeRestriction } from "../types";

/**
 * Formats a time restriction as a sortable and displayable object.
 * @param data Time restriction.
 */
export const formatTimeRestrictionDate = (data: TimeRestriction) => {
    const { dayOfWeek, endTime, startTime } = data;

    return {
        sortPredicate: `${daysConsistent[dayOfWeek].order} ${startTime}-${endTime}`,
        value: `${daysConsistent[dayOfWeek].name} ${startTime}-${endTime}`,
    };
};
