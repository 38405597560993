import React from "react";
import styled from "styled-components";
import assets from "../../common/assets";
import colors from "../../common/colors";
import {
    CellBlockProperty,
    CellBlockSection,
    CellBlockTitle,
    CellBlockValue,
} from "../../components/Grid/CellBlock";
import { HandlaProxyValidation } from "./customerDetailsConfig";
import { Customer } from "./customerTypes";

interface Props extends Customer {
    handlaProxyValidation: HandlaProxyValidation;
}

const CustomerInfoListing = (props: Props) => {
    const {
        firstName,
        lastName,
        line1,
        postalCode,
        town,
        phone,
        doorCode,
        orderPickerMessage,
        handlaProxyValidation,
    } = props;

    const defaultErrors = handlaProxyValidation.errors.filter(
        (e) => e.section === "default"
    );

    const blockedErrors = handlaProxyValidation.errors.filter(
        (e) => e.section === "blocked"
    );

    return (
        <>
            <CellBlockSection>
                <CellBlockTitle>{`${firstName} ${lastName}`}</CellBlockTitle>
                <CellBlockValue>{line1}</CellBlockValue>
                <CellBlockValue>{`${postalCode} ${town}`}</CellBlockValue>
            </CellBlockSection>
            <CellBlockSection>
                {phone?.length ? (
                    <CellBlockSection>
                        <CellBlockProperty>Telefon</CellBlockProperty>
                        <CellBlockValue>{phone}</CellBlockValue>
                    </CellBlockSection>
                ) : null}
                {doorCode?.length ? (
                    <CellBlockSection>
                        <CellBlockProperty>Portkod</CellBlockProperty>
                        <CellBlockValue>{doorCode}</CellBlockValue>
                    </CellBlockSection>
                ) : null}
            </CellBlockSection>
            {orderPickerMessage?.length ? (
                <CellBlockSection>
                    <CellBlockProperty>
                        Kommentar till beställare och plockare
                    </CellBlockProperty>
                    <CellBlockValue>{orderPickerMessage}</CellBlockValue>
                </CellBlockSection>
            ) : null}

            {defaultErrors.length > 0 && (
                <CellBlockSection>
                    <ErrorWrapper>
                        <Icon>{assets.exclamation}</Icon>
                        {defaultErrors.length === 1 ? (
                            <span>{`${
                                defaultErrors[0].action
                            } ${defaultErrors[0].message.toLowerCase()} för att kunna handla`}</span>
                        ) : (
                            <span>{`Fyll i alla obligatoriska fält för att kunna handla`}</span>
                        )}
                    </ErrorWrapper>
                </CellBlockSection>
            )}

            {blockedErrors?.map((error) => (
                <CellBlockSection>
                    <ErrorWrapper>
                        <Icon>{assets.exclamation}</Icon>
                        {error.action && error.action + " "}
                        <span>{error.message}</span>
                    </ErrorWrapper>
                </CellBlockSection>
            ))}
        </>
    );
};

const Icon = styled.div`
    min-height: 20px;
    max-height: 20px;
    min-width: 20px;
    max-width: 20px;
    display: inline-block;
    margin-right: 10px;
    margin-top: -1px;
    fill: ${colors.red};
`;

const ErrorWrapper = styled.div`
    color: ${colors.red};
    display: flex;
    align-items: center;
`;

export default CustomerInfoListing;
