import React from "react";
import styled from "styled-components";

export const CriticalErrorFallback = () => (
    <ErrorContainer>
        <h1>Nånting gick väldigt snett.</h1>
        <p>Vänligen ladda om sidan.</p>
    </ErrorContainer>
);

export const ErrorContainer = styled.div`
    font-family: "Akkurat";
    display: flex;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;
