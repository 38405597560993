import { trimStart } from "./stringUtils";

export const mapTruthyValuesToQueryStringParams = (
    initialSearch: string,
    values: Record<string, string | number | undefined>
) => {
    const newParams = new URLSearchParams(initialSearch);
    Object.entries(values).forEach(([key, value]) => {
        if (value !== undefined && value !== null && value !== "") {
            newParams.set(key, value.toString());
        } else {
            newParams.delete(key);
        }
    });

    return newParams;
};

export const trimLeadingSlash = (input: string) => {
    return trimStart(input, "/");
};
