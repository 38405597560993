import { Log, UserManager, WebStorageStateStore } from "oidc-client";
import { identityConfig, pathSignIn } from "./authConst";

class AuthService {
    UserManager: UserManager;

    constructor() {
        this.UserManager = new UserManager({
            ...identityConfig,
            userStore: new WebStorageStateStore({
                store: window.sessionStorage,
            }),
        });

        this.UserManager.events.addUserSignedOut(() => {
            this.UserManager.removeUser().then(() => {
                console.log("User signed out, navigating to sign in page");
                window.location.href = pathSignIn;
            });
        });

        this.UserManager.events.addAccessTokenExpired(() => {
            this.UserManager.removeUser().then(() => {
                console.log("Access token expired, navigating to sign in page");
                window.location.href = pathSignIn;
            });
        });
    }

    signinRedirect = () =>
        this.UserManager.signinRedirect({
            extraQueryParams: {
                LoginType: "municipal",
            },
        });

    signinRedirectCallback = () => this.UserManager.signinRedirectCallback();

    getUser = () => this.UserManager.getUser();

    signout = () =>
        Promise.all([
            this.UserManager.signoutRedirect(),
            this.UserManager.clearStaleState(),
        ]);
}

window.enableAuthLogging = () => {
    Log.logger = console;
    Log.level = Log.DEBUG;
};

window.disableAuthLogging = () => {
    Log.reset();
};

export default AuthService;
