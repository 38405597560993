import classNames from "classnames";
import * as React from "react";
import assets from "../../common/assets";

import styles from "./Search.module.scss";

interface SearchContentProps {
    inputPlaceholder: string;
    inputText?: string;
    defaultValue?: string;
    onInputChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
    onInputClick?: (e: React.MouseEvent) => void;
    onClearClick?: (e: React.MouseEvent) => void;
    onInputFocus?: (e: React.FocusEvent<HTMLInputElement>) => void;
    onInputBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
    onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
    onSearchIconPress?: () => void;
}

const SearchContent = React.forwardRef<HTMLInputElement, SearchContentProps>(
    (props, inputRef) => {
        return (
            <div className={classNames(styles["Search-content"], "u-noPrint")}>
                <button
                    type="button"
                    className={classNames(
                        styles["Search-icon"],
                        "u-outlineSolidBase2 u-outlineInside"
                    )}
                    aria-label="Sök"
                    onClick={props.onSearchIconPress || undefined}
                    disabled={!props.onSearchIconPress}>
                    {assets.search}
                </button>
                <input
                    ref={inputRef}
                    className={styles["Search-input"]}
                    type="search"
                    defaultValue={props.defaultValue}
                    placeholder={props.inputPlaceholder}
                    onChange={props.onInputChange || undefined}
                    value={props.inputText}
                    onFocus={props.onInputFocus || undefined}
                    onBlur={props.onInputBlur || undefined}
                    onKeyDown={props.onKeyDown || undefined}
                    onClick={props.onInputClick || undefined}
                    size={1} // Enables it to shrink below default 20 which is needed for small screens
                />
                {props.inputText && (
                    <button
                        className={classNames(
                            styles["Search-icon"],
                            "u-outlineSolidBase2 u-outlineInside"
                        )}
                        type="button"
                        onClick={props.onClearClick || undefined}>
                        {assets.cross}
                    </button>
                )}
            </div>
        );
    }
);

export default SearchContent;
