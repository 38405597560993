import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useContext } from "react";
import { customerMethods } from "../../api/apiMethods";
import { FieldError, HTTPError } from "../../common/types";
import { isHybrisValidationError } from "../../common/validations";
import { UserContext } from "../../contexts/UserContext";
import { queryKeys } from "../../queries/queryKeys";
import { Logger } from "../../utils/logger/Logger";
import { CustomerFormData } from "../../views/CustomerView/CustomerView.hooks";
import {
    extractFieldErrorsFromHybrisErrorAsArray,
    mapCustomerFormToUpdateBillingRequest,
    mapCustomerFormToUpdateRequest,
    mapHybrisCustomerBillingErrorField,
    mapHybrisCustomerInfoErrorField,
} from "../../views/CustomerView/customerDetailsConfig";

class CustomerFormErrorWithType extends Error {
    innerError: unknown;
    type: "billing" | "restrictions" | "payment" | "customer";
    customerId?: string;

    constructor(
        message: string,
        type: "billing" | "restrictions" | "payment" | "customer",
        innerError: unknown,
        customerId?: string
    ) {
        super(message);
        this.innerError = innerError;
        this.type = type;
        this.customerId = customerId;
    }
}

export const useCustomerCreateMutation = (options: {
    onSuccess?: (customerId: string) => void;
    onGenericError: (args: {
        message: string;
        jsonError: unknown | undefined;
    }) => void;
    onFieldValidationError: (args: {
        fieldErrors?: FieldError[];
        message: string;
        jsonError: unknown | undefined;
    }) => void;
    onBillingCreateError: (
        customerId: string,
        args: {
            message: string;
            jsonError: unknown | undefined;
        }
    ) => void;
}) => {
    const { authUserData } = useContext(UserContext);
    const queryClient = useQueryClient();

    const mutation = useMutation({
        mutationFn: async ({ formData }: { formData: CustomerFormData }) => {
            if (!authUserData) {
                throw new Error("No auth user data");
            }

            if (
                !formData.unit ||
                !formData.timeRestrictions ||
                !formData.paymentInfoId
            ) {
                throw new Error("Invalid form data");
            }

            let customerId: string | undefined;

            // CREATE CUSTOMER
            try {
                customerId = (
                    await customerMethods(
                        authUserData.municipalityCode
                    ).createCustomer(
                        mapCustomerFormToUpdateRequest(formData),
                        formData.unit.id
                    )
                ).customerID;
            } catch (e) {
                throw new CustomerFormErrorWithType(
                    "Kunde inte spara uppgifterna",
                    "customer",
                    e
                );
            }

            if (!customerId) {
                throw new Error("No customer id");
            }

            const updateBillingRequestBody =
                mapCustomerFormToUpdateBillingRequest(formData);
            if (
                updateBillingRequestBody &&
                (formData.paymentInfoId === "intrum" ||
                    formData.paymentInfoId === "collectiveinvoice")
            ) {
                try {
                    // CREATE BILLING ADDRESS
                    await customerMethods(
                        authUserData.municipalityCode
                    ).createCustomerBilling(
                        updateBillingRequestBody,
                        formData.unit.id,
                        customerId
                    );
                } catch (e) {
                    throw new CustomerFormErrorWithType(
                        "Kunde inte spara uppgifterna",
                        "billing",
                        e,
                        customerId
                    );
                }
            }

            // UPDATE RESTRICTIONS
            try {
                await customerMethods(
                    authUserData.municipalityCode
                ).updateCustomerRestrictions(
                    formData.timeRestrictions.map(
                        (restriction) => restriction.extraData
                    ),
                    formData.unit.id,
                    customerId
                );
            } catch (e) {
                throw new CustomerFormErrorWithType(
                    "Kunde inte spara leveranstid",
                    "restrictions",
                    e
                );
            }

            // UPDATE PAYMENT INFO
            try {
                await customerMethods(
                    authUserData.municipalityCode
                ).updateCustomerPaymentInfo(
                    formData.paymentInfoId,
                    formData.unit.id,
                    customerId
                );
            } catch (e) {
                throw new CustomerFormErrorWithType(
                    "Kunde inte spara betalningsmetod",
                    "payment",
                    e
                );
            }

            return customerId;
        },
        onSuccess: (customerId) => {
            queryClient.invalidateQueries({
                queryKey: queryKeys.customer.full,
            });
            options.onSuccess?.(customerId);
        },
        onError: async (error) => {
            Logger.error(error);

            if (error instanceof CustomerFormErrorWithType) {
                const jsonError = await tryParseResponse(error.innerError);

                switch (error.type) {
                    case "billing": {
                        if (
                            isHybrisValidationError(jsonError) &&
                            jsonError.errors.length > 0 &&
                            (error.innerError as Response).status ===
                                HTTPError._400_BAD_REQUEST
                        ) {
                            const fieldErrors: FieldError[] =
                                extractFieldErrorsFromHybrisErrorAsArray(
                                    jsonError,
                                    mapHybrisCustomerBillingErrorField
                                );
                            options.onFieldValidationError({
                                fieldErrors,
                                message: error.message,
                                jsonError,
                            });
                        }

                        if (error.customerId) {
                            options.onBillingCreateError(error.customerId, {
                                message: error.message,
                                jsonError,
                            });
                            return;
                        }

                        options.onGenericError({
                            message: error.message,
                            jsonError,
                        });

                        break;
                    }
                    case "customer": {
                        if (
                            isHybrisValidationError(jsonError) &&
                            jsonError.errors.length > 0 &&
                            (error.innerError as Response).status ===
                                HTTPError._400_BAD_REQUEST
                        ) {
                            const fieldErrors: FieldError[] =
                                extractFieldErrorsFromHybrisErrorAsArray(
                                    jsonError,
                                    mapHybrisCustomerInfoErrorField
                                );
                            options.onFieldValidationError({
                                fieldErrors,
                                message: error.message,
                                jsonError,
                            });
                            return;
                        }

                        options.onGenericError({
                            message: error.message,
                            jsonError,
                        });

                        break;
                    }
                    case "restrictions":
                        options.onGenericError({
                            message: error.message,
                            jsonError,
                        });
                        break;
                    case "payment":
                        options.onGenericError({
                            message: error.message,
                            jsonError,
                        });
                        break;
                    default:
                        options.onGenericError({
                            message: "Kunde inte spara uppgifterna",
                            jsonError,
                        });
                        break;
                }
            } else {
                options.onGenericError({
                    message: "Kunde inte spara uppgifterna",
                    jsonError: tryParseResponse(error),
                });
            }
        },
    });

    return mutation;
};

const tryParseResponse = async (error: unknown) => {
    try {
        error = await (error as Response).json();
        Logger.error(error);
        return error;
    } catch {
        return undefined;
    }
};
