import React from "react";
import styled from "styled-components";
import { breakpoint, dimension, space } from "../common/variables";

interface Props {
    size?: "small" | "medium";
    children: React.ReactNode;
}

const Container = (props: Props) => {
    return <Wrapper size={props.size}>{props.children}</Wrapper>;
};

const Wrapper = styled.div`
    margin: 0 auto;
    width: 100%;
    height: 100%;
    position: relative;
    padding: 0 ${space.paddingSmall};

    max-width: ${({ size }: { size?: "small" | "medium" }) =>
        size === "small"
            ? dimension.containerSmall
            : size === "medium"
            ? dimension.containerMedium
            : dimension.containerLarge};

    @media ${breakpoint[900]} {
        padding: 0 ${space.padding};
    }
`;

export default Container;
