import { PaymentCodes } from "../../views/CustomerView/customerTypes";
import { PaymentTypes } from "../translations";

/**
 * Converts Payment code into Swedish resemblance.
 * @param code Payment code.
 */
export const translatePaymentCode = (code: PaymentCodes): PaymentTypes => {
    switch (code) {
        case "intrum":
            return "Faktura";
        case "matkonto":
            return "Matkonto";
        case "collectiveinvoice":
            return "Samlingsfaktura";
        default:
            return "Okänt";
    }
};
