import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useContext } from "react";
import { ordererMethods } from "../api/apiMethods";
import { genericErrorConfig } from "../common/exceptions";
import { ModalContext } from "../components/Modal";
import { UserContext } from "../contexts/UserContext";
import { queryKeys } from "../queries/queryKeys";
import { Logger } from "../utils/logger/Logger";

export const useDeleteOrdererMutation = (options: {
    onSuccess: () => void;
}) => {
    const { authUserData } = useContext(UserContext);
    const { setModalOptions } = useContext(ModalContext);
    const queryClient = useQueryClient();

    const mutation = useMutation({
        mutationFn: (ordererId?: string) => {
            if (!authUserData) {
                throw new Error("No auth user data");
            }

            if (!ordererId) {
                throw new Error("No orderer id");
            }

            try {
                return ordererMethods(
                    authUserData.municipalityCode
                ).deleteOrderer(ordererId);
            } catch (err) {
                throw err;
            }
        },
        onSuccess: options.onSuccess,
        onError: (err) => {
            Logger.error(err);
            setModalOptions(
                genericErrorConfig(() => {
                    setModalOptions({ visible: false });
                }, "Kunde inte ta bort beställare.")
            );
        },
        onSettled: () => {
            queryClient.invalidateQueries({ queryKey: queryKeys.orderer.all });
        },
    });

    return mutation;
};
