import { useCallback, useState } from "react";
import { useSpring } from "react-spring";
import useOutsideClick from "../common/helpers/useOutsideClick";

export const useAnimatedDropdownState = (
    ref: React.RefObject<HTMLElement>,
    expandedCallback?: (newExpanded: boolean) => void
) => {
    const [isExpanded, setIsExpanded] = useState(false);
    const [animProps, api] = useSpring(() => ({
        opacity: 0,
        transform: `translateY(-10px)`,
        config: { mass: 0.6, tension: 80, friction: 8 },
    }));

    const expand = useCallback(() => {
        api.start({
            opacity: 1,
            transform: `translateY(0)`,
        });
        setIsExpanded(true);
        expandedCallback?.(true);
    }, [api, expandedCallback]);

    const collapse = useCallback(() => {
        api.start({
            opacity: 0,
            transform: `translateY(-10px)`,
        });
        setIsExpanded(false);
        expandedCallback?.(false);
    }, [api, expandedCallback]);

    const toggle = useCallback(() => {
        if (isExpanded) {
            collapse();
        } else {
            expand();
        }
    }, [collapse, expand, isExpanded]);

    useOutsideClick(ref, () => {
        if (isExpanded) {
            collapse();
        }
    });

    return {
        isExpanded,
        animProps,
        toggle,
        expand,
        collapse,
    };
};
