export const capitalizeFirstCharacter = (s: string) => {
    if (typeof s !== 'string') return ''
    return s.charAt(0).toUpperCase() + s.slice(1)
}

export const stringIsEmpty = (str: string | undefined | null) => {
    if(str === undefined || str === null || str.toString().trim().length === 0){
        return true;
    } else {
        return false;
    }
}