import {
    createColumnHelper,
    getCoreRowModel,
    getSortedRowModel,
    useReactTable,
} from "@tanstack/react-table";
import React, { useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Button from "../../components/Button";
import Container from "../../components/Container";
import EmptyState from "../../components/EmptyState";
import Loader from "../../components/Loader";
import Plate from "../../components/Plate";
import SubHeader from "../../components/SubHeader";
import Table from "../../components/Table/Table";
import { useAllUnitsQuery } from "../../queries/units/useAllUnitsQuery";
import { useAllOrderersQuery } from "../../queries/useAllOrderersQuery";
import { filterOrderersAuthorizedUnits } from "./OrdererView.utils";
import { OrdererListingItem } from "./ordererTypes";

const columnHelper = createColumnHelper<OrdererListingItem>();
const columns = [
    columnHelper.accessor(
        (row) => row.orderer.firstName + " " + row.orderer.lastName,
        {
            id: "name",
            header: () => "Namn",
            cell: (props) => props.getValue(),
            meta: {
                getCellContext: () => {
                    return {
                        length: 3,
                    };
                },
            },
        }
    ),
    columnHelper.accessor((row) => row.orderer.municipalEmail, {
        id: "email",
        header: () => "E-postadress",
        enableSorting: false,
        cell: (props) => props.getValue(),
        meta: {
            getCellContext: () => {
                return {
                    length: 3,
                };
            },
        },
    }),
    columnHelper.accessor(
        (row) =>
            row.authUnits?.length
                ? `${row.authUnits
                      ?.filter((o, i) => i < 3)
                      .map((o) => o.name)
                      .join(", ")}`
                : "Inga enheter",
        {
            id: "units",
            header: () => "Enhet",
            enableSorting: false,
            cell: (props) => props.getValue(),
            meta: {
                getCellContext: () => {
                    return {
                        length: 3,
                    };
                },
            },
        }
    ),
];

const OrdererListingView = () => {
    const navigate = useNavigate();
    const { pathname } = useLocation();

    const allUnitsQuery = useAllUnitsQuery();
    const allOrderersQuery = useAllOrderersQuery();

    const data = useMemo(
        () =>
            allOrderersQuery.data &&
            allUnitsQuery.data &&
            filterOrderersAuthorizedUnits(
                allOrderersQuery.data,
                allUnitsQuery.data
            ),
        [allOrderersQuery.data, allUnitsQuery.data]
    );

    const table = useReactTable({
        data: data || [],
        columns: columns,
        getCoreRowModel: getCoreRowModel(),
        getSortedRowModel: getSortedRowModel(),
    });

    return (
        <Container>
            <SubHeader
                title="Beställare"
                rightContent={
                    <Button
                        size="small"
                        onClick={() => {
                            navigate(pathname + "/skapa-ny");
                        }}
                        title="Skapa ny"
                    />
                }
            />
            <Plate spaceBottom={true} overScroll>
                {allUnitsQuery.isLoading || allOrderersQuery.isLoading ? (
                    <Loader />
                ) : !allOrderersQuery.data?.length ? (
                    <EmptyState text="Det finns inga beställare." />
                ) : (
                    <Table
                        table={table}
                        getLinkTo={(data) => pathname + "/" + data.orderer.uid}
                    />
                )}
            </Plate>
        </Container>
    );
};

export default OrdererListingView;
