import { appConfig } from "../appConfig";
import { identityConfig } from "../auth/authConst";

const API_KEY = appConfig.API_KEY;

export interface HTTPMethods {
    POST: keyof HTTPMethods;
    PUT: keyof HTTPMethods;
    GET: keyof HTTPMethods;
    DELETE: keyof HTTPMethods;
    PATCH: keyof HTTPMethods;
}

export const httpMethods: HTTPMethods = {
    POST: "POST",
    PUT: "PUT",
    GET: "GET",
    DELETE: "DELETE",
    PATCH: "PATCH",
};

export const ensureHttpSuccess = (response: Response) => {
    if (!response.ok) {
        return Promise.reject(response);
        // throw new FetchError(response);
    }

    return Promise.resolve(response);
};

export const getAuthToken = (): string | null => {
    const authToken = sessionStorage.getItem(
        `oidc.user:${identityConfig.authority}:${identityConfig.client_id}`
    );

    if (!authToken) {
        return null;
    }

    return JSON.parse(authToken).access_token;
};

export const parseJSON = <T>(response: Response) => {
    return response.json() as Promise<T>;
};

export const sendJSON = (
    data: any,
    token: string,
    httpMethod: keyof HTTPMethods = "POST",
    contentType: string = "application/x-www-form-urlencoded"
): RequestInit => {
    const bodyData =
        contentType === "application/x-www-form-urlencoded"
            ? Object.keys(data)
                  .map((key) => {
                      return (
                          encodeURIComponent(key) +
                          "=" +
                          (data[key] !== undefined
                              ? encodeURIComponent(data[key])
                              : "")
                      );
                  })
                  .join("&")
            : JSON.stringify(data);

    const request: RequestInit = {
        method: httpMethod,
        body: bodyData,
        headers: {
            "Content-Type": `${contentType};charset=utf-8`,
            Authorization: `Bearer ${token}`,
            Accept: "*/*",
            "Ocp-Apim-Subscription-Key": API_KEY,
        },
    };

    request.credentials = "omit";

    return request;
};

/// https://lists.w3.org/Archives/Public/www-archive/2017Aug/0000.html
export const withoutCache = (input: RequestInfo): RequestInfo => {
    let newInput = input;
    if (typeof newInput === "string") {
        const timestampCacheBusting = `timestamp=${new Date().getTime()}`;
        if (newInput.includes("?")) {
            newInput += `&${timestampCacheBusting}`;
        } else {
            newInput += `?${timestampCacheBusting}`;
        }
    }
    return newInput;
};

export const fetchJSON = (
    token: string,
    httpMethod: keyof HTTPMethods = "GET"
): RequestInit => ({
    method: httpMethod,
    headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
        "Ocp-Apim-Subscription-Key": API_KEY,
    },
    credentials: "omit",
});

export class FetchError extends Error {
    response: Response;

    constructor(response: Response) {
        super(
            `Http error ${response.status} while fetching from ${response.url}, ${response.statusText}`
        );
        this.response = response;
    }
}
