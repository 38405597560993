import { z } from "zod";
import {
    addressLineSchema,
    allPaymentsSchema,
    emailSchema,
    firstNameSchema,
    homePhoneSchema,
    invoicePaymentSchema,
    lastNameSchema,
    matkontoPaymentSchema,
    medmeraIdSchema,
    mobilePhoneNotRequiredSchema,
    optionalEmailSchema,
    postalCodeSchema,
    ssnSchema,
    timeRestrictionsSchema,
    townSchema,
    unitSchema,
} from "../../common/zodSchemas";
import { customerBillingSchema } from "./customerBillingFormSchema";

const selfOrderUnion = z
    .object({
        selfOrder: z.boolean().default(false),
    })
    .and(
        z.discriminatedUnion("selfOrder", [
            z.object({
                selfOrder: z.literal(true),
                registrationEmail: emailSchema,
            }),
            z.object({ selfOrder: z.literal(false) }),
        ])
    );

const transactionalEmailUnion = z
    .object({
        hasTransactionalEmail: z.boolean().default(false),
    })
    .and(
        z.discriminatedUnion("hasTransactionalEmail", [
            z.object({
                hasTransactionalEmail: z.literal(true),
                transactionalEmail: z
                    .array(z.object({ value: emailSchema }))
                    .min(1),
            }),
            z.object({ hasTransactionalEmail: z.literal(false) }),
        ])
    );

const customerFormSchema = z
    .object({
        firstName: firstNameSchema,
        lastName: lastNameSchema,
        line1: addressLineSchema,
        postalCode: postalCodeSchema,
        town: townSchema,
        phone: mobilePhoneNotRequiredSchema.optional(),
        homePhone: homePhoneSchema.optional(),
        doorCode: z.string().optional(),
        email: optionalEmailSchema.optional(),
        orderPickerMessage: z.string().optional(),
        unit: unitSchema,
        timeRestrictions: timeRestrictionsSchema,
        defaultDriverMessage: z.string().optional(),
        medmeraId: medmeraIdSchema.optional(),
        helpToUnpack: z.boolean().optional().default(false),
    })
    .and(selfOrderUnion)
    .and(transactionalEmailUnion);

// SSN and billing are only required for invoice-like payments
export const createCustomerFormSchema = customerFormSchema
    .and(
        z.object({
            paymentInfoId: allPaymentsSchema,
        })
    )
    .and(
        z.discriminatedUnion("paymentInfoId", [
            z.object({
                paymentInfoId: invoicePaymentSchema,
                billing: customerBillingSchema,
                socialSecurityNumber: ssnSchema,
            }),
            z.object({ paymentInfoId: matkontoPaymentSchema }),
        ])
    );

// SSN and billing are only required for invoice-like payments, but contrary to createSchema, edit schema's SSN is optional (there might be users that didnt add SSN so we dont want to force them to add it whenever their details are edited)
export const editCustomerFormSchema = customerFormSchema
    .and(
        z.object({
            paymentInfoId: allPaymentsSchema,
        })
    )
    .and(
        z.discriminatedUnion("paymentInfoId", [
            z.object({
                paymentInfoId: invoicePaymentSchema,
                billing: customerBillingSchema,
                socialSecurityNumber: ssnSchema.optional(),
            }),
            z.object({ paymentInfoId: matkontoPaymentSchema }),
        ])
    );
