import Cookies from "js-cookie";

interface MatkontoTmpVerifyInfo {
    customerId: string;
    unitId: string;
}

const MATKONTO_COOKIE_KEY = "MatkontoVerifyInfo";

// This is temporary crap - when going back from Matkonto payex, we need to call "verify" endpoint with previously selected customer.
// We keep that in a cookie, so we must delete that cookie on every page that is not a matkonto callback page
// This needs to be fixed on Hybrs endpoint by giving us customerId and unitId i.e. as a query string parameters when calling our 'callback/matkonto' redirect.
export const MatkontoTmpInfoProvider = {
    get: (): MatkontoTmpVerifyInfo | null => {
        const matkontoCookie = Cookies.get(MATKONTO_COOKIE_KEY);
        if (!matkontoCookie) return null;

        const tmpCustomerInfo: MatkontoTmpVerifyInfo = JSON.parse(
            matkontoCookie
        );

        if (tmpCustomerInfo.customerId && tmpCustomerInfo.unitId) {
            return tmpCustomerInfo;
        } else return null;
    },

    set: (data: MatkontoTmpVerifyInfo) => {
        Cookies.set(MATKONTO_COOKIE_KEY, JSON.stringify(data));
    },

    remove: () => {
        Cookies.remove(MATKONTO_COOKIE_KEY);
    },

    exists: (): boolean => {
        const matkontoCookie = Cookies.get(MATKONTO_COOKIE_KEY);
        return !!matkontoCookie;
    },
};
