import React from "react";
import styled from "styled-components";
import colors from "../common/colors";
import { fontSize } from "../common/variables";

type TagTheme = "white-green" | "white-black" | "red-light" | "green-light";

const Tag = ({
    children,
    theme,
    style,
    hasNotification,
    count,
}: {
    children: React.ReactNode;
    theme?: TagTheme;
    style?: React.CSSProperties;
    hasNotification?: boolean;
    count?: number;
}) => (
    <Container theme={theme}>
        <Content style={style} theme={theme}>
            {children}
        </Content>
        {hasNotification && <Notification />}
        {count !== undefined && <Count>{count}</Count>}
    </Container>
);

export default Tag;

const getBgColor = (color?: TagTheme) => {
    switch (color) {
        case "green-light":
            return colors.greenLight;
        case "red-light":
            return colors.pink;
        default:
        case "white-green":
        case "white-black":
            return colors.white;
    }
};

const getColor = (color?: TagTheme) => {
    switch (color) {
        case "green-light":
            return colors.greenDark;
        case "red-light":
            return colors.redDark;
        case "white-green":
            return colors.green;
        default:
        case "white-black":
            return colors.black;
    }
};

const getBorderColor = (color?: TagTheme) => {
    switch (color) {
        case "green-light":
            return "transparent";
        case "red-light":
            return "transparent";
        case "white-green":
            return "rgba(0, 0, 0, 0.1)";
        default:
        case "white-black":
            return "rgba(0, 0, 0, 0.1)";
    }
};

const Container = styled.div<{
    theme?: TagTheme;
}>`
    width: max-content;
    position: relative;
    pointer-events: none;
    display: flex;
    align-items: center;
    padding-left: 4px;
    padding-right: 4px;
    padding-top: 2px;
    padding-bottom: 2px;
    border: 1.5px solid ${(props) => getBorderColor(props.theme)};
    border-radius: 10px;

    background-color: ${(props) => getBgColor(props.theme)};
`;

const Content = styled.span<{
    theme?: TagTheme;
}>`
    display: inline-block;
    color: ${(props) => getColor(props.theme)};

    font-size: ${fontSize.xSmall};
    line-height: ${fontSize.medium};
    font-weight: 400;

    padding-left: 4px;
    padding-right: 4px;
    font-size: 12px;

    margin: 0;
    > * {
        margin: 0;
    }
`;

const Notification = styled.span`
    position: absolute;
    top: -4px;
    right: -4px;
    height: 10px;
    width: 10px;
    background-color: ${colors.pinkDark};
    border-radius: 5px;
`;

const Count = styled.div`
    background-color: #0a893d;
    border-radius: 999px;
    color: white;
    font-size: ${fontSize.xSmall};
    line-height: ${fontSize.medium};
    padding-left: 4.5px;
    padding-right: 4.5px;
    vertical-align: middle;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 16px;
    min-width: 16px;
`;
