import React from "react";
import styled from "styled-components";
import colors from "../common/colors";
import { byName } from "../common/helpers/sortFunctions";
import { ListOption } from "../common/types";
import SelectableListItem from "./SelectableListItem";

interface Props {
    data: ListOption[];
    selectedData: ListOption[];
    overflowValue?: string;
    setChoice: (option: ListOption) => void;
    style?: React.CSSProperties;
}

const OptionsList = (props: Props) => {
    return (
        <Wrapper overflow={props.overflowValue} style={props.style}>
            <Content>
                {props.data.sort(byName).map((option) => {
                    const selected = !!props.selectedData.find(
                        (sel) => sel.id === option.id
                    );

                    return (
                        <SelectableListItem
                            key={option.id}
                            onClick={() => props.setChoice(option)}
                            selected={selected}
                            large
                            title={option.name}
                        />
                    );
                })}
            </Content>
        </Wrapper>
    );
};

export default OptionsList;

const Wrapper = styled.div`
    overflow: auto;
    max-height: 400px;
    border-top: 1px solid ${colors.border};
    border-bottom: 1px solid ${colors.border};
    margin: ${({ overflow }: { overflow?: string }) =>
        overflow ? `0 -${overflow}` : 0};
`;

const Content = styled.div`
    display: flex;
    flex-direction: column;
`;
