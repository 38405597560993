// Freeze outlet in order to support animations when using transitions

import { useState } from "react";
import { useOutlet } from "react-router-dom";

// https://github.com/remix-run/react-router/discussions/8008#discussioncomment-1280897
const AnimatedOutlet = () => {
    let [context] = useState(useOutlet());
    return context;
};

export default AnimatedOutlet;
