import React from "react";
import styled from "styled-components";
import colors from "../common/colors";
import { groupTimeRestrictions } from "../common/helpers/groupTimeRestrictions";
import { daysConsistent, DaysConsistentEnum } from "../common/translations";
import { TimeRestriction } from "../common/types";
import { space } from "../common/variables";
import SelectableListItem from "./SelectableListItem";
import Tag from "./Tag";

interface Props {
    data: TimeRestriction[];
    selectedData: TimeRestriction[];
    overflowValue?: string;
    setChoice: (restriction: TimeRestriction) => void;
}

const TimeFrameList = (props: Props) => {
    return (
        <Wrapper overflow={props.overflowValue}>
            {props.data.length ? (
                groupTimeRestrictions(props.data).map((section, index) => (
                    <Section key={index} overflow={props.overflowValue}>
                        <div>
                            <Tag>
                                <strong>{section.dayOfWeek}</strong>
                            </Tag>
                        </div>
                        <Row>
                            {section.times.map((time, innerIndex) => {
                                const selected = !!props.selectedData.find(
                                    (sel) =>
                                        daysConsistent[sel.dayOfWeek].name ===
                                            section.dayOfWeek &&
                                        `${sel.startTime}-${sel.endTime}` ===
                                            `${time.startTime}-${time.endTime}`
                                );

                                return (
                                    <SelectableListItem
                                        key={`${index}_${innerIndex}`}
                                        selected={selected}
                                        title={`${time.startTime}-${time.endTime}`}
                                        style={{
                                            width: "auto",
                                            marginRight: space.xxLarge,
                                            marginTop: space.small,
                                        }}
                                        onClick={() =>
                                            props.setChoice({
                                                dayOfWeek:
                                                    DaysConsistentEnum[
                                                        section.dayOfWeek
                                                    ],
                                                startTime: time.startTime,
                                                endTime: time.endTime,
                                            })
                                        }
                                    />
                                );
                            })}
                        </Row>
                    </Section>
                ))
            ) : (
                <EmptyText>Det finns inga tillgängliga tidsfönster.</EmptyText>
            )}
        </Wrapper>
    );
};

export default TimeFrameList;

const Wrapper = styled.div`
    display: flex;
    overflow: auto;
    max-height: 400px;
    flex-direction: column;
    border-top: 1px solid ${colors.border};
    border-bottom: 1px solid ${colors.border};
    margin: ${({ overflow }: { overflow?: string }) =>
        overflow ? `0 -${overflow}` : 0};
`;

const Section = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 60px;
    flex-shrink: 0;
    border-top: 1px solid ${colors.border};
    padding: ${({ overflow }: { overflow?: string }) =>
        overflow ? `${space.small} ${overflow}` : `${space.small} 0`};

    &:first-of-type {
        border-top: none;
    }
`;

const Row = styled.div`
    display: flex;
    flex-wrap: wrap;
`;

const EmptyText = styled.span`
    color: ${colors.grayDark};
    text-align: center;
    padding: ${space.medium} 0;
`;
