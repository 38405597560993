import React from "react";
import styled from "styled-components";
import assets from "../../common/assets";
import colors from "../../common/colors";
import { formatTimeRestrictionDate } from "../../common/helpers/formatTimeRestrictionDate";
import Checkbox from "../../components/Checkbox";
import {
    CellBlockProperty,
    CellBlockSection,
    CellBlockValue,
} from "../../components/Grid/CellBlock";
import {
    HandlaProxyValidation,
    formatSSN,
    isInvoicePayment,
} from "./customerDetailsConfig";
import { Customer } from "./customerTypes";

interface Props extends Customer {
    handlaProxyValidation: HandlaProxyValidation;
}

const CustomerDeliveryListing = (props: Props) => {
    const {
        defaultDriverMessage,
        helpToUnpack,
        timeRestrictions,
        unit,
        selfOrder,
        handlaProxyValidation,
        socialSecurityNumber,
        paymentInfo,
    } = props;

    const errors = handlaProxyValidation.errors.filter(
        (e) => e.section === "billing"
    );

    let ssnToDisplay = null;
    // changes YYYYMMDDNNNN from api into YYMMDD-XXXX
    if (isInvoicePayment(paymentInfo?.id) && socialSecurityNumber) {
        ssnToDisplay = formatSSN(socialSecurityNumber);
    }

    return (
        <>
            {unit?.name.length && (
                <CellBlockSection>
                    <CellBlockProperty>Enhet</CellBlockProperty>
                    <CellBlockValue>{unit?.name}</CellBlockValue>
                </CellBlockSection>
            )}
            {!!timeRestrictions?.length && (
                <CellBlockSection>
                    <CellBlockProperty>Leveranstid</CellBlockProperty>
                    <CellBlockValue>
                        {timeRestrictions.map((t, index) => (
                            <React.Fragment key={index}>
                                {formatTimeRestrictionDate(t).value}
                                <br />
                            </React.Fragment>
                        ))}
                    </CellBlockValue>
                </CellBlockSection>
            )}
            {props.paymentInfo?.name && (
                <CellBlockSection>
                    <CellBlockProperty>Betalsätt</CellBlockProperty>
                    <CellBlockValue>{props.paymentInfo?.name}</CellBlockValue>
                </CellBlockSection>
            )}
            {defaultDriverMessage?.length ? (
                <CellBlockSection>
                    <CellBlockProperty>
                        Kommentar till chaufför
                    </CellBlockProperty>
                    <CellBlockValue>{defaultDriverMessage}</CellBlockValue>
                </CellBlockSection>
            ) : null}
            {props.medmeraId && (
                <CellBlockSection>
                    <CellBlockProperty>MedMera-ID</CellBlockProperty>
                    <CellBlockValue>{props.medmeraId}</CellBlockValue>
                </CellBlockSection>
            )}
            {props.socialSecurityNumber &&
                isInvoicePayment(props.paymentInfo?.id) && (
                    <CellBlockSection>
                        <CellBlockProperty>Personnummer</CellBlockProperty>
                        <CellBlockValue>{ssnToDisplay}</CellBlockValue>
                    </CellBlockSection>
                )}
            {helpToUnpack && (
                <CellBlockSection>
                    <Checkbox
                        value={true}
                        text="Behöver hjälp med upplockning av varor"
                        iconColor={colors.grayDark}
                        readOnly
                    />
                </CellBlockSection>
            )}
            <CellBlockSection>
                <Checkbox
                    value={selfOrder}
                    text="Kan handla själv"
                    iconColor={colors.grayDark}
                    readOnly
                />
            </CellBlockSection>

            {errors.length > 0 && (
                <CellBlockSection>
                    <ErrorWrapper>
                        <Icon>{assets.exclamation}</Icon>
                        {errors.length === 1 ? (
                            <span>{`${
                                errors[0].action
                            } ${errors[0].message.toLowerCase()} för att kunna handla`}</span>
                        ) : (
                            <span>{`Fyll i alla obligatoriska fält för att kunna handla`}</span>
                        )}
                    </ErrorWrapper>
                </CellBlockSection>
            )}
        </>
    );
};

const Icon = styled.div`
    min-height: 20px;
    max-height: 20px;
    min-width: 20px;
    max-width: 20px;
    display: inline-block;
    margin-right: 10px;
    margin-top: -1px;
    fill: ${colors.red};
`;

const ErrorWrapper = styled.div`
    color: ${colors.red};
    display: flex;
    align-items: center;
`;

export default CustomerDeliveryListing;
