import { ControllerFieldState } from "react-hook-form";
import { HybrisFieldValidationResponse } from "./types";

export interface ValidationResult {
    isValid?: boolean;
    isDirty?: boolean;
    message?: string;
}

export const isRequiredMessage = "Fältet får inte vara tomt";
export const getIsFieldRequiredMessage = (fieldName: string) =>
    `${fieldName} får inte vara tomt`;

export const digitsOnlyPattern = /^[0-9]*$/;
export const digitsOnlyInvalidMessage = "Fältet får endast innehålla siffror";

export const emailInvalidMessage = "E-postadressen är felaktig";

export const postalCodePattern = /^[0-9]{3}\s?[0-9]{2}$/;
export const postalCodeInvalidMessage = "Postnumret är ogiltigt";

export const phoneNrPattern = /\(?([0-9]{2})\)?([ .-]?)([0-9]{3})\2([0-9]{2})/;
export const phoneInvalidMessage = "Telefonnumret är ogiltigt";

export const mobileNrPattern = /^((((0{2}?)|([+]){1})46)|0)7[\d]{8}$/;
export const mobileNumberInvalidMessage = "Mobilnumret är ogiltigt";

export const maxLengthInvalidMessage = (length: number) =>
    `Max ${length} tecken`;

export const isNoDigitsPattern = /^([^0-9]*)$/;
export const noDigitsInvalidMessage = "Fältet får endast innehålla bokstäver";

export const medmeraIdInvalidMessage = `Ogiltigt MedMera-ID. Skall vara 7 siffror `;

export const ssnPattern = /^\d{8}[-]?\d{4}$/;
export const ssnInvalidMessage = `Personnumret är felaktigt (korrekt format: YYYYMMDD-XXXX)`;

export const getValidationResultFromFormError = (
    fieldState: ControllerFieldState
) => {
    return {
        isDirty: fieldState.isDirty,
        isValid: !fieldState.invalid,
        message: fieldState.error?.message,
    };
};

export const isHybrisValidationError = (
    error: unknown
): error is HybrisFieldValidationResponse => {
    return (
        error !== undefined &&
        (error as HybrisFieldValidationResponse).errors !== undefined
    );
};
