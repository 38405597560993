import { useQuery } from "@tanstack/react-query";
import { useContext } from "react";
import { municipalityMethods } from "../api/apiMethods";
import { translatePaymentCode } from "../common/helpers/convertPaymentMethod";
import { UserContext } from "../contexts/UserContext";
import { useQueryErrorHandling } from "../hooks/useQueryErrorHandling";
import { queryKeys } from "./queryKeys";

export const useAvailablePaymentMethodsForMunicipalityQuery = () => {
    const { authUserData } = useContext(UserContext);

    const fetchUnauthoried =
        !authUserData ||
        (authUserData &&
            authUserData.municipalityRole === "ORDERER" &&
            !authUserData.customerCreationAllowed);

    const query = useQuery({
        queryKey: queryKeys.municipality.availablePaymentMethods(
            authUserData?.municipalityCode
        ),
        queryFn: () => {
            if (!authUserData?.municipalityCode) {
                throw new Error("Missing municipality code");
            }

            try {
                return municipalityMethods(
                    authUserData.municipalityCode
                ).getAvailablePaymentMethods();
            } catch (error) {
                throw new Error("Could not get available payment methods");
            }
        },
        enabled: !!authUserData?.municipalityCode && !fetchUnauthoried,
        select: ({ paymentModes }) =>
            paymentModes.map((payment) => ({
                id: payment.code,
                name: translatePaymentCode(payment.code),
            })),
    });

    useQueryErrorHandling(query, "Kunde inte hämta betalmetoder.");

    return query;
};
