import React from "react";
import { Navigate } from "react-router-dom";
import { pathSignIn } from "../auth/authConst";
import Loader from "../components/Loader";
import { useIsAuthenticated } from "../hooks/useIsAuthenticated";

export const Authenticated = ({ children }: React.PropsWithChildren<{}>) => {
    const isAuthenticated = useIsAuthenticated();

    if (isAuthenticated === true) {
        return children;
    }

    if (isAuthenticated === false) {
        return <Navigate to={pathSignIn} />;
    }

    return <Loader fullHeight />;
};
