import { DaysConsistentType } from "./translations";

export interface ListOption<NAME = string, ID = string, DATA = any> {
    id: ID;
    name: NAME;
    extraData?: DATA;
}

export const TimeRestrictionDayOfWeek = [
    "SUNDAY",
    "MONDAY",
    "TUESDAY",
    "WEDNESDAY",
    "THURSDAY",
    "FRIDAY",
    "SATURDAY",
] as const;

export interface TimeRestriction {
    dayOfWeek: (typeof TimeRestrictionDayOfWeek)[number];
    endTime: string;
    startTime: string;
    replacementHint?: boolean;
}

export interface ReplacementWindow {
    active: boolean;
    cost: number;
    endTime: string;
    id: string;
    replacementWindow: boolean;
    startTime: string;
    stopTime: string;
    timeSlotDay: string;
    timeSlotDayShort: string;
    timeSlotTime: string;
}

export interface ReplacementWindowGroup {
    replacedHomeTimeWindow: ReplacementWindow;
    replacementHomeTimeWindow: ReplacementWindow;
}

export interface ReplacementWindowResponse {
    windowReplacementConfigurations: ReplacementWindowGroup[];
}

export const HTTPError = {
    _400_BAD_REQUEST: 400,
};

// Flat object version of FieldErrors
export type FieldError = {
    fieldName: string;
    isDirty: boolean;
    isValid: boolean;
    message: string;
};

export type FieldErrors = {
    [fieldName: string]: {
        isDirty: boolean;
        isValid: boolean;
        message: string;
    };
};

export interface HybrisFieldError {
    message: string;
    reason: string;
    subject: string;
}

export interface HybrisFieldValidationResponse {
    errors: HybrisFieldError[];
}

export interface MunicipalityAdmin {
    authorisedUnitCodes: string[];
    currency: { active: false; isocode: string; symbol: string };
    customerId: string;
    displayUid: string;
    firstName: string;
    language: { active: boolean; isocode: string };
    lastName: string;
    municipalityCode: string;
    municipalityRole: string;
    name: string;
    uid: string;
}

export interface Municipality {
    code: string;
    name: string;
    municpalityAdmins: MunicipalityAdmin[];
}

export interface TimeFrameListSection {
    dayOfWeek: DaysConsistentType;
    times: {
        startTime: string;
        endTime: string;
    }[];
}
