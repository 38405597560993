import { UserManagerSettings } from "oidc-client";
import { appConfig } from "../appConfig";

const path = window.location.origin;

export const pathBase = "/";
export const pathSignIn = "/logga-in";
export const pathAuthCallback = "/callback";

export const identityConfig: UserManagerSettings = {
    authority: appConfig.LOGIN_URL,
    client_id: appConfig.LOGIN_CLIENT_ID,
    response_type: "code",
    scope: "openid hybris_api hybris.profile is.user",
    redirect_uri: `${path}${pathAuthCallback}`,
    post_logout_redirect_uri: `${path}${pathSignIn}`,
    silent_redirect_uri: `${path}/callback.html`,

    // filterProtocolClaims: true
};
