import { zodResolver } from "@hookform/resolvers/zod";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { TimeRestriction } from "../../common/types";
import {
    isRequiredMessage,
    phoneInvalidMessage,
    phoneNrPattern,
    postalCodeInvalidMessage,
    postalCodePattern,
} from "../../common/validations";
import { compareTimeRestrictionsPredicate } from "./UnitView.utils";

const unitInfoFormSchema = z.object({
    name: z.string().min(1, { message: isRequiredMessage }),
    line1: z.string().min(1, { message: isRequiredMessage }),
    postalCode: z
        .string()
        .regex(postalCodePattern, { message: postalCodeInvalidMessage })
        .min(1, { message: isRequiredMessage }),
    town: z.string().min(1, { message: isRequiredMessage }),
    stairs: z.string().optional(),
    doorCode: z.string().optional(),
    phone: z
        .string()
        .regex(phoneNrPattern, { message: phoneInvalidMessage })
        .min(1, { message: isRequiredMessage }),
});

export type UnitInfoFormData = z.infer<typeof unitInfoFormSchema>;

export const useUnitInfoForm = () => {
    const form = useForm<UnitInfoFormData>({
        resolver: zodResolver(unitInfoFormSchema),
        mode: "onTouched",
    });

    return form;
};

export const useSelectedTimeFrames = (params?: { onDeselect?: () => void }) => {
    const [selectedTimeFrames, setSelectedTimeFrames] = useState<
        TimeRestriction[]
    >([]);

    const updateSelectedTimeframes = (selectedTimeFrame: TimeRestriction) => {
        const alreadySelected = selectedTimeFrames?.find((frame) =>
            compareTimeRestrictionsPredicate(frame, selectedTimeFrame)
        );

        if (alreadySelected) {
            const updatedTimeFrames = [...selectedTimeFrames].filter(
                (frame) =>
                    !compareTimeRestrictionsPredicate(frame, selectedTimeFrame)
            );
            setSelectedTimeFrames(updatedTimeFrames);
            params?.onDeselect?.();
        } else {
            setSelectedTimeFrames([...selectedTimeFrames, selectedTimeFrame]);
        }
    };

    return {
        selectedTimeFrames,
        updateSelectedTimeframes,
        setSelectedTimeFrames,
    };
};
