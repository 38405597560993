import { User } from "oidc-client";
import { useContext, useEffect, useState } from "react";
import { AuthContext } from "../auth/authProvider";
import { Logger } from "../utils/logger/Logger";
import { telemetry } from "../utils/telemetry/TelemetryService";

export const useIsAuthenticated = () => {
    const { getUser } = useContext(AuthContext);
    const [isAuthenticated, setIsAuthenticated] = useState<boolean>();

    useEffect(() => {
        getUser()
            .then((user: User | null) => {
                if (user) {
                    setIsAuthenticated(true);
                    telemetry.setAuthenticatedUserContext(user.profile.sub);
                } else {
                    setIsAuthenticated(false);
                    telemetry.clearAuthenticatedUserContext();
                }
            })
            .catch((err: any) => {
                Logger.error(err);
            });
    }, [getUser]);

    return isAuthenticated;
};
