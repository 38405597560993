import { telemetry } from "../telemetry/TelemetryService";

const concatStringErrors = (...strings: string[]) =>
    strings.filter(Boolean).join(". ");

const logErrortype = (param: Error) => {
    telemetry.trackError(param);
};

const logStringType = (param: string) => {
    telemetry.trackError(new Error(param));
};

const logResponseType = (param: Response, msg?: string) => {
    param
        .clone()
        .text()
        .then((text) => {
            telemetry.trackError(new Error(msg || "API error"), {
                extra: text,
            });
        });

    return;
};

const logOtherType = (param: any, msg?: string) => {
    telemetry.trackError(new Error(msg || "Unknown error"), param);
};

export const Logger = {
    log: (param: any) => {
        telemetry.trackInfo(param);
    },
    error: (param: string | Error | any) => {
        if (param instanceof Error) {
            logErrortype(param);
            return;
        }

        if (typeof param === "string") {
            logStringType(param);
            return;
        }

        if (param instanceof Response) {
            logResponseType(param);
            return;
        }

        logOtherType(param);
    },
    errorWithMessage: (message: string, param: string | Error | any) => {
        if (param instanceof Error) {
            logErrortype({
                ...param,
                message: concatStringErrors(message, param.message),
            });
            return;
        }

        if (typeof param === "string") {
            logStringType(concatStringErrors(message, param));
            return;
        }

        if (param instanceof Response) {
            logResponseType(param, message);
            return;
        }

        logOtherType(param, message);
    },
};
