import React from "react";

const IconCross = () => (
    <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path d="M4.46967 4.46918C4.76256 4.17629 5.23744 4.17629 5.53033 4.46918L12 10.9389L18.4697 4.46918C18.7626 4.17629 19.2374 4.17629 19.5303 4.46918C19.8232 4.76207 19.8232 5.23695 19.5303 5.52984L13.0607 11.9995L19.5303 18.4692C19.8232 18.7621 19.8232 19.2369 19.5303 19.5298C19.2374 19.8227 18.7626 19.8227 18.4697 19.5298L12 13.0602L5.53033 19.5298C5.23744 19.8227 4.76256 19.8227 4.46967 19.5298C4.17678 19.2369 4.17678 18.7621 4.46967 18.4692L10.9393 11.9995L4.46967 5.52984C4.17678 5.23695 4.17678 4.76207 4.46967 4.46918Z" />
    </svg>
);

const IconPlus = () => (
    <svg
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        width="100%"
        height="100%"
        viewBox="0 0 1000 1000">
        <g>
            <path d="M938.9,448.9H551.1V61.4c0-28.2-22.9-51.1-51.1-51.1c-28.2,0-51.1,22.9-51.1,51.1v387.6H61.1C32.9,448.9,10,471.8,10,500c0,28.2,22.9,51.1,51.1,51.1h387.8v387.5c0,28.2,22.9,51.1,51.1,51.1c28.2,0,51.1-22.9,51.1-51.1V551.1h387.8c28.2,0,51.1-22.9,51.1-51.1C990,471.8,967.1,448.9,938.9,448.9z" />
        </g>
    </svg>
);

const IconCopy = () => (
    <svg
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        width="100%"
        height="100%"
        viewBox="0 0 488.3 488.3">
        <g>
            <path
                d="M314.25,85.4h-227c-21.3,0-38.6,17.3-38.6,38.6v325.7c0,21.3,17.3,38.6,38.6,38.6h227c21.3,0,38.6-17.3,38.6-38.6V124
                C352.75,102.7,335.45,85.4,314.25,85.4z M325.75,449.6c0,6.4-5.2,11.6-11.6,11.6h-227c-6.4,0-11.6-5.2-11.6-11.6V124
                c0-6.4,5.2-11.6,11.6-11.6h227c6.4,0,11.6,5.2,11.6,11.6V449.6z"
            />
            <path
                d="M401.05,0h-227c-21.3,0-38.6,17.3-38.6,38.6c0,7.5,6,13.5,13.5,13.5s13.5-6,13.5-13.5c0-6.4,5.2-11.6,11.6-11.6h227
                c6.4,0,11.6,5.2,11.6,11.6v325.7c0,6.4-5.2,11.6-11.6,11.6c-7.5,0-13.5,6-13.5,13.5s6,13.5,13.5,13.5c21.3,0,38.6-17.3,38.6-38.6
                V38.6C439.65,17.3,422.35,0,401.05,0z"
            />
        </g>
    </svg>
);

const IconArrowLeft = () => (
    <svg
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        width="100%"
        height="100%"
        viewBox="0 0 459 459">
        <g id="keyboard-backspace">
            <polygon points="459,204 96.9,204 188.7,112.2 153,76.5 0,229.5 153,382.5 188.7,346.8 96.9,255 459,255" />
        </g>
    </svg>
);

const IconArrowRight = () => (
    <svg
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        width="100%"
        height="100%"
        viewBox="0 0 459 459">
        <g id="keyboard-backspace">
            <polygon points="0,255 362.1,255 270.3,346.8 306,382.5 459,229.5 306,76.5 270.3,112.2 362.1,204 0,204" />
        </g>
    </svg>
);

const IconTrash = () => (
    <svg
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        width="100%"
        height="100%"
        viewBox="0 0 774.266 774.266">
        <g>
            <path
                d="M640.35,91.169H536.971V23.991C536.971,10.469,526.064,0,512.543,0c-1.312,0-2.187,0.438-2.614,0.875
                C509.491,0.438,508.616,0,508.179,0H265.212h-1.74h-1.75c-13.521,0-23.99,10.469-23.99,23.991v67.179H133.916
                c-29.667,0-52.783,23.116-52.783,52.783v38.387v47.981h45.803v491.6c0,29.668,22.679,52.346,52.346,52.346h415.703
                c29.667,0,52.782-22.678,52.782-52.346v-491.6h45.366v-47.981v-38.387C693.133,114.286,670.008,91.169,640.35,91.169z
                M285.713,47.981h202.84v43.188h-202.84V47.981z M599.349,721.922c0,3.061-1.312,4.363-4.364,4.363H179.282
                c-3.052,0-4.364-1.303-4.364-4.363V230.32h424.431V721.922z M644.715,182.339H129.551v-38.387c0-3.053,1.312-4.802,4.364-4.802
                H640.35c3.053,0,4.365,1.749,4.365,4.802V182.339z"
            />
            <rect x="475.031" y="286.593" width="48.418" height="396.942" />
            <rect x="363.361" y="286.593" width="48.418" height="396.942" />
            <rect x="251.69" y="286.593" width="48.418" height="396.942" />
        </g>
    </svg>
);

const IconCheckMark = () => (
    <svg
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        width="100%"
        height="100%"
        fillRule="evenodd"
        clipRule="evenodd"
        viewBox="0 0 3168 3168">
        <g>
            <path d="M1584 0c875 0 1584 709 1584 1584s-709 1584-1584 1584S0 2459 0 1584 709 0 1584 0zM879 1745c-20-18-31-43-32-69-1-25 7-51 25-72 0 0 0-1 1-1 18-20 43-31 69-32 25-1 52 7 72 26l334 303 801-839c19-20 44-30 70-31s51 8 71 28c20 19 30 44 31 70s-8 51-28 72l-869 910-2 2c-18 18-43 28-67 29-25 1-51-8-71-26l-406-368z" />
        </g>
    </svg>
);

const IconExclamation = () => (
    <svg
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        width="100%"
        height="100%"
        fillRule="evenodd"
        clipRule="evenodd"
        viewBox="0 0 4130 4130">
        <g>
            <path d="M1931 3000c-7-17-11-36-11-55 0-20 4-38 11-55s18-33 32-47 30-25 47-32 36-11 55-11c20 0 38 4 56 11 17 7 32 17 46 31 0 0 1 0 1 1 14 14 25 30 32 47s11 36 11 55c0 20-4 38-11 55s-18 33-32 47-30 25-47 32-36 11-55 11c-20 0-38-4-55-11s-32-17-46-31c0 0-1 0-1-1-14-14-25-30-32-47zm134-2827c-131 0-258 12-381 37s-242 61-356 110c-116 50-224 108-324 175S811 639 726 724c-86 85-162 178-229 278s-126 208-175 324c-1 1-1 3-2 4-48 113-84 230-108 352-25 123-37 250-37 381s12 258 37 381 61 242 110 356c50 116 108 224 175 324s144 193 229 278c85 86 178 162 278 229s208 126 324 175c1 1 3 1 4 2 113 48 230 84 352 108 123 25 250 37 381 37s258-12 381-37 242-61 356-110c116-50 224-108 324-175s193-144 278-229c86-85 162-178 229-278s126-208 175-324c1-1 1-3 2-4 48-113 84-230 108-352 25-123 37-250 37-381s-12-258-37-381-61-242-110-356c-50-116-108-224-175-324s-144-193-229-278c-85-86-178-162-278-229s-208-126-324-175c-1-1-3-1-4-2-113-48-230-84-352-108-123-25-250-37-381-37zM1650 40c134-27 272-40 415-40 142 0 280 13 415 40 132 26 261 66 386 119 2 1 3 1 5 2 125 54 242 117 352 191s211 157 305 251c94 93 177 195 251 305s137 227 191 352c54 126 94 256 121 390s40 272 40 415c0 142-13 280-40 415-26 132-66 261-119 386-1 2-1 3-2 5-54 125-117 242-191 352s-157 211-251 305c-93 94-195 177-305 251s-227 137-352 191c-126 54-256 94-390 121s-272 40-415 40c-142 0-280-13-415-40-132-26-261-66-386-119-2-1-3-1-5-2-125-54-242-117-352-191s-211-157-305-251c-94-93-177-195-251-305s-137-227-191-352c-54-126-94-256-121-390S0 2209 0 2066c0-142 13-280 40-415 26-132 66-261 119-386 1-2 1-3 2-5 54-125 117-242 191-352s157-211 251-305c93-94 195-177 305-251s227-137 352-191c126-54 256-94 390-121zm504 2575c0 34-24 56-55 65-11 3-23 5-35 5s-24-2-35-5c-31-9-55-31-55-65l-115-1502v-1c0-37 55-59 125-68 25-3 52-5 80-5 27 0 54 2 80 5 70 9 125 31 125 68v2l-115 1501z" />
        </g>
    </svg>
);

const IconAngleLeft = () => (
    <svg
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        width="100%"
        height="100%"
        fillRule="evenodd"
        clipRule="evenodd"
        viewBox="0 0 640 640">
        <g>
            <path d="M484.093 579.196c13.82 14.197 13.512 36.863-.685 50.682-14.197 13.795-36.863 13.476-50.682-.709L155.99 343.788l25.69-24.98-25.796 25.016c-13.82-14.257-13.465-37.005.768-50.824.425-.402.838-.78 1.263-1.157l274.81-281c13.82-14.197 36.485-14.516 50.682-.72 14.197 13.818 14.504 36.496.685 50.68l-252.38 258.11 252.38 260.283z" />
        </g>
    </svg>
);

const IconAngleRight = () => (
    <svg
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        width="100%"
        height="100%"
        fillRule="evenodd"
        clipRule="evenodd"
        viewBox="0 0 640 640">
        <g>
            <path d="M155.907 579.196c-13.82 14.197-13.512 36.863.685 50.682 14.197 13.795 36.863 13.476 50.682-.709l274.81-281-25.69-24.98 25.796 25.016c13.82-14.257 13.465-37.005-.768-50.824-.425-.402-.838-.78-1.263-1.157l-274.81-281c-13.82-14.197-36.485-14.516-50.682-.72-14.197 13.818-14.504 36.496-.685 50.68l252.38 258.11-252.38 260.283z" />
        </g>
    </svg>
);

const IconUser = () => (
    <svg
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        width="100%"
        height="100%"
        viewBox="0 0 20 20">
        <g>
            <path d="M10 12.5c-5.92 0-9 3.5-9 5.5v1h18v-1c0-2-3.08-5.5-9-5.5z" />
            <circle cx="10" cy="6" r="5" />
        </g>
    </svg>
);

const IconInfo = () => (
    <svg viewBox="0 0 22 22" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M11 1.74951C5.89137 1.74951 1.75 5.89088 1.75 10.9995C1.75 16.1081 5.89137 20.2495 11 20.2495C16.1086 20.2495 20.25 16.1081 20.25 10.9995C20.25 5.89088 16.1086 1.74951 11 1.74951ZM0.25 10.9995C0.25 5.06245 5.06294 0.249512 11 0.249512C16.9371 0.249512 21.75 5.06245 21.75 10.9995C21.75 16.9366 16.9371 21.7495 11 21.7495C5.06294 21.7495 0.25 16.9366 0.25 10.9995ZM8.25 8.99951C8.25 8.5853 8.58579 8.24951 9 8.24951H11C11.4142 8.24951 11.75 8.5853 11.75 8.99951V15.2495H13C13.4142 15.2495 13.75 15.5853 13.75 15.9995C13.75 16.4137 13.4142 16.7495 13 16.7495H9C8.58579 16.7495 8.25 16.4137 8.25 15.9995C8.25 15.5853 8.58579 15.2495 9 15.2495H10.25V9.74951H9C8.58579 9.74951 8.25 9.41372 8.25 8.99951Z"
        />
        <path d="M12.5 5.49951C12.5 6.32794 11.8284 6.99951 11 6.99951C10.1716 6.99951 9.5 6.32794 9.5 5.49951C9.5 4.67108 10.1716 3.99951 11 3.99951C11.8284 3.99951 12.5 4.67108 12.5 5.49951Z" />
    </svg>
);

const IconSearch = () => (
    <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path d="M15.2983 6.45949C12.8575 4.01871 8.90027 4.01871 6.45949 6.45949C4.01871 8.90027 4.01871 12.8575 6.45949 15.2983C8.90027 17.7391 12.8575 17.7391 15.2983 15.2983C17.7391 12.8575 17.7391 8.90027 15.2983 6.45949ZM5.39883 5.39883C8.42539 2.37227 13.3324 2.37227 16.359 5.39883C19.2066 8.24648 19.375 12.7589 16.864 15.8033L20.6016 19.541C20.8945 19.8339 20.8945 20.3087 20.6016 20.6016C20.3087 20.8945 19.8339 20.8945 19.541 20.6016L15.8033 16.864C12.7589 19.375 8.24648 19.2066 5.39883 16.359C2.37227 13.3324 2.37227 8.42539 5.39883 5.39883Z" />
    </svg>
);

interface Icons {
    cross: React.ReactNode;
    plus: React.ReactNode;
    arrowLeft: React.ReactNode;
    arrowRight: React.ReactNode;
    copy: React.ReactNode;
    trash: React.ReactNode;
    checkmark: React.ReactNode;
    exclamation: React.ReactNode;
    angle: React.ReactNode;
    angleRight: React.ReactNode;
    user: React.ReactNode;
    info: React.ReactNode;
    search: React.ReactNode;
}

const icons: Icons = {
    cross: <IconCross />,
    plus: <IconPlus />,
    arrowLeft: <IconArrowLeft />,
    arrowRight: <IconArrowRight />,
    copy: <IconCopy />,
    trash: <IconTrash />,
    checkmark: <IconCheckMark />,
    exclamation: <IconExclamation />,
    angle: <IconAngleLeft />,
    angleRight: <IconAngleRight />,
    user: <IconUser />,
    info: <IconInfo />,
    search: <IconSearch />,
};

interface Images {
    logo: string;
    background: string;
    screen: string;
}

const images: Images = {
    logo: "/resources/images/logo.png",
    background: "/resources/images/background.jpg",
    screen: "/resources/images/screen.png",
};

export default {
    ...icons,
    ...images,
};
