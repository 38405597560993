import React, { useState, useRef, useEffect } from "react";
import styled, { css } from "styled-components";
import { fontSize, space } from "../common/variables";
import colors from "../common/colors";
import mixins from "../common/mixins";

export interface TabBarView {
    title: string;
    component: React.ReactNode;
    panelPadding?: boolean;
}

interface CurrentView extends TabBarView {
    index: number;
}

interface TitleBounds {
    width: number;
    positionX: number;
}

interface Props {
    data: TabBarView[];
    initialViewIndex?: number;
}

const TabBar = (props: Props) => {
    const { data, initialViewIndex = 0 } = props;
    const titleRefs = useRef<any[]>([]); // really didn't know how to type-check this shit
    const [titleBounds, setTitleBounds] = useState<TitleBounds[]>();
    const [currentView, setCurrentView] = useState<CurrentView>({
        ...data[initialViewIndex],
        index: initialViewIndex,
    });

    useEffect(() => {
        const bounds = titleRefs.current.map<TitleBounds>((ref) => ({
            width: ref.offsetWidth,
            positionX: ref.offsetLeft,
        }));
        setTitleBounds(bounds);
    }, [data, titleRefs, currentView]);

    return (
        <Wrapper>
            <Header>
                {data.map((item, i) => (
                    <Title
                        key={i}
                        ref={(ref) =>
                            titleRefs.current && (titleRefs.current[i] = ref)
                        }
                        active={currentView.index === i}
                        onClick={() => setCurrentView({ ...item, index: i })}>
                        {item.title}
                    </Title>
                ))}
                <Indicator
                    bounds={titleBounds && titleBounds[currentView.index]}
                />
            </Header>

            {data.map(
                (item, i) =>
                    currentView.index === i && (
                        <Panel key={i} padding={currentView?.panelPadding}>
                            <Container key={i}>{item.component}</Container>
                        </Panel>
                    )
            )}
        </Wrapper>
    );
};

export default TabBar;

const Wrapper = styled.div``;

const Header = styled.div`
    display: flex;
    height: 70px;
    padding: 0 ${space.padding};
    position: relative;
    border-bottom: 1px solid ${colors.grayLighter};
`;

const Title = styled.button`
    cursor: pointer;
    font-size: ${fontSize.medium};
    margin-right: ${space.xLarge};
    color: ${colors.grayDarker};
    position: relative;
    transition: color ${mixins.transitions.default};

    ${({ active }: { active?: boolean }) =>
        active &&
        css`
            color: ${colors.black};
        `};
`;

const Indicator = styled.div`
    position: absolute;
    height: 3px;
    left: 0;
    bottom: 0;
    background-color: ${colors.green};
    transition: width ${mixins.transitions.default},
        transform ${mixins.transitions.default};

    ${({ bounds }: { bounds?: TitleBounds }) =>
        bounds &&
        css`
            width: ${bounds.width}px;
            transform: translateX(${bounds.positionX}px);
        `};
`;

const Panel = styled.div`
    ${({ padding }: { padding?: boolean }) =>
        padding &&
        css`
            padding: ${space.padding};
        `};
`;

const Container = styled.div``;
