import { useQuery } from "@tanstack/react-query";
import { useContext } from "react";
import { unitMethods } from "../../api/apiMethods";
import { UserContext } from "../../contexts/UserContext";
import { useQueryErrorHandling } from "../../hooks/useQueryErrorHandling";
import { queryKeys } from "../queryKeys";

export const useUnitReplacementWindowsQuery = (unitId?: string) => {
    const { authUserData } = useContext(UserContext);

    const fetchAuthorized =
        authUserData && authUserData.municipalityRole === "ADMIN";

    const query = useQuery({
        queryKey: queryKeys.municipality.restrictions(
            authUserData?.municipalityCode
        ),
        queryFn: () => {
            if (!authUserData?.municipalityCode) {
                throw new Error("Missing municipality code");
            }

            if (!unitId) {
                throw new Error("Missing unit id");
            }

            try {
                return unitMethods(
                    authUserData.municipalityCode
                ).getUnitReplacementWindows(unitId);
            } catch (error) {
                throw new Error("Could not get unit replacement windows.");
            }
        },
        enabled: fetchAuthorized,
        select: ({ windowReplacementConfigurations }) =>
            windowReplacementConfigurations,
    });

    useQueryErrorHandling(query, "Kunde inte hämta ersättningsfönster.");

    return query;
};
