import { ListOption } from "../types";

export const byName = (
    a: ListOption<string, string, any>,
    b: ListOption<string, string, any>
) => {
    const nA = a?.name?.toLowerCase();
    const nB = b?.name?.toLowerCase();
    if (nA && nB) {
        if (nA < nB) return -1;
        else if (nA > nB) return 1;
        return 0;
    }
    return 0;
};
