import { useQuery } from "@tanstack/react-query";
import { useContext } from "react";
import { unitMethods } from "../../api/apiMethods";
import { UserContext } from "../../contexts/UserContext";
import { useQueryErrorHandling } from "../../hooks/useQueryErrorHandling";
import { queryKeys } from "../queryKeys";

export const useAllUnitsQuery = () => {
    const { authUserData } = useContext(UserContext);

    const query = useQuery({
        queryKey: queryKeys.unit.all(authUserData?.municipalityCode),
        queryFn: () => {
            if (!authUserData) {
                throw new Error("No auth user data");
            }

            if (!authUserData.municipalityCode) {
                throw new Error("Missing municipality code");
            }

            try {
                return unitMethods(authUserData.municipalityCode).getAllUnits();
            } catch (error: any) {
                throw new Error("Could not get all units");
            }
        },
        select: ({ municipalityUnit: units }) => {
            if (authUserData?.municipalityRole === "ADMIN") {
                return units;
            }

            if (authUserData?.municipalityRole === "ORDERER") {
                // this API endpoint returns all possible units, not just units for current user, so we must filter others out
                return units.filter((unit) =>
                    authUserData.authorisedUnitCodes?.includes(unit.code)
                );
            }

            return [];
        },
        enabled: !!authUserData,
        staleTime: Infinity,
    });

    useQueryErrorHandling(query, "Kunde inte hämta enheter.");

    return query;
};
