import "core-js/features/string/replace-all";
import "core-js/proposals/string-replace-all";
import "core-js/stable";
import "core-js/stable/url";
import "core-js/stable/url-search-params";
import React from "react";
import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import ReactDOM from "react-dom/client";
import "regenerator-runtime/runtime";
import App from "./App";

const domNode = document.getElementById("root");
const root = ReactDOM.createRoot(domNode!);
root.render(<App />);
