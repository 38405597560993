import { RefObject, useEffect, useLayoutEffect, useRef } from "react";

/**
 * Helper for the classic "trigger @callback when clicked outside of".
 * @param ref The reference node, which will trigger the callback when clicking outside of.
 * @param callback Function that triggers when conditions are met.
 */
const useOutsideClick = <TRef extends HTMLElement>(
    ref: RefObject<TRef>,
    callback: () => void
) => {
    const refCb = useRef(callback);

    useLayoutEffect(() => {
        refCb.current = callback;
    });

    useEffect(() => {
        const handler = (e: Event) => {
            const element = ref.current;
            if (element && !element.contains(e.target as Element)) {
                refCb.current();
            }
        };

        document.addEventListener("mousedown", handler);
        document.addEventListener("touchstart", handler);

        return () => {
            document.removeEventListener("mousedown", handler);
            document.removeEventListener("touchstart", handler);
        };
    }, [ref]);

    return ref;
};

export default useOutsideClick;
