import React from "react";
import styled, { css } from "styled-components";
import colors from "../common/colors";
import extractValue from "../common/helpers/extractValue";
import mixins from "../common/mixins";
import { breakpoint, space } from "../common/variables";

interface Props {
    spaceTop?: boolean;
    spaceBottom?: boolean;
    padding?: "small" | "medium" | "large" | "xLarge";
    faded?: boolean;
    outerChildren?: React.ReactNode;
    noShadow?: boolean;
    backgroundColor?: string;
    children?: React.ReactNode;
    style?: React.CSSProperties;
}

const Block = (props: Props) => {
    return (
        <Wrapper
            noShadow={props.noShadow}
            spaceTop={props.spaceTop}
            faded={props.faded}
            style={props.style}
            spaceBottom={props.spaceBottom}
            backgroundColor={props.backgroundColor}>
            {props.outerChildren}
            <Content padding={props.padding}>{props.children}</Content>
        </Wrapper>
    );
};

export default Block;

const Wrapper = styled.div<{
    spaceBottom?: boolean;
    spaceTop?: boolean;
    noShadow?: boolean;
    faded?: boolean;
    backgroundColor?: string;
}>`
    background-color: ${({ backgroundColor }) =>
        backgroundColor ? backgroundColor : colors.white};
    border-radius: ${mixins.borderRadius.default};
    opacity: ${({ faded }) => (faded ? 0.5 : 1)};
    box-shadow: ${({ noShadow }) =>
        noShadow ? "none" : mixins.boxShadow.spread};
    transition: opacity ${mixins.transitions.default};

    ${({ spaceBottom, spaceTop }) => {
        if (spaceTop && spaceBottom) {
            return css`
                margin-top: ${space.paddingSmall};
                margin-bottom: ${space.paddingSmall};

                @media ${breakpoint.small} {
                    margin-top: ${space.padding};
                    margin-bottom: ${space.padding};
                }
            `;
        }

        if (spaceTop) {
            return css`
                margin-top: ${space.paddingSmall};

                @media ${breakpoint.small} {
                    margin-top: ${space.padding};
                }
            `;
        }

        if (spaceBottom) {
            return css`
                margin-bottom: ${space.paddingSmall};

                @media ${breakpoint.small} {
                    margin-bottom: ${space.padding};
                }
            `;
        }

        return css`
            margin-bottom: 0;
        `;
    }};
`;

const Content = styled.div`
    ${({ padding }: { padding?: "small" | "medium" | "large" | "xLarge" }) =>
        padding === "small"
            ? css`
                  padding: ${space.paddingSmall};
              `
            : padding === "medium"
            ? css`
                  padding: ${space.paddingSmall};

                  @media ${breakpoint.small} {
                      padding: ${space.padding};
                  }
              `
            : padding === "large"
            ? css`
                  padding: ${space.padding};
              `
            : padding === "xLarge"
            ? css`
                  padding: ${extractValue(space.padding) * 2}px;
              `
            : null};
`;
