import React from "react";
import styled, { css } from "styled-components";
import colors from "../common/colors";
import { breakpoint, space } from "../common/variables";
import Block from "./Block";

interface Props {
    spaceBottom?: boolean;
    spaceTop?: boolean;
    children?: React.ReactNode;
}
export const ReplacementTimeWindow = (props: Props) => {
    return (
        <Wrapper spaceBottom={props.spaceBottom} spaceTop={props.spaceTop}>
            <Block
                style={{padding: '20px 25px'}}
                backgroundColor={colors.grayLighter}
                noShadow>
                {props.children}
            </Block>
            <Exclamation>!</Exclamation>
        </Wrapper>
    );
};

const Wrapper = styled.div<{
    spaceBottom?: boolean;
    spaceTop?: boolean;
}>`
    position: relative;

    ${({ spaceBottom, spaceTop }) => {
        if (spaceTop && spaceBottom) {
            return css`
                margin-top: ${space.paddingSmall};
                margin-bottom: ${space.paddingSmall};

                @media ${breakpoint.small} {
                    margin-top: ${space.padding};
                    margin-bottom: ${space.padding};
                }
            `;
        }

        if (spaceTop) {
            return css`
                margin-top: ${space.paddingSmall};

                @media ${breakpoint.small} {
                    margin-top: ${space.padding};
                }
            `;
        }

        if (spaceBottom) {
            return css`
                margin-bottom: ${space.paddingSmall};

                @media ${breakpoint.small} {
                    margin-bottom: ${space.padding};
                }
            `;
        }

        return css`
            margin-bottom: 0;
        `;
    }};
`;

const Exclamation = styled.div`
    color: ${colors.white};
    position: absolute;
    background-color: ${colors.pinkDark};
    height: 20px;
    width: 20px;
    top: -5px;
    right: -5px;
    border-radius: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: default;
`;

interface ReplacedTimeWindowProps {
    children?: React.ReactNode;
}
export const ReplacedTimeWindowLabel = (props: ReplacedTimeWindowProps) => {
    return <WrapperReplaced>{props.children}</WrapperReplaced>;
};

const WrapperReplaced = styled.p`
    font-weight: 700;
    font-size: 16px;
    margin-top: 0;
    margin-bottom: 8px;
`;

interface ReplacementTimeWindowProps {
    children?: React.ReactNode;
}
export const ReplacementTimeWindowLabel = (
    props: ReplacementTimeWindowProps
) => {
    return <WrapperReplacement>{props.children}</WrapperReplacement>;
};

const WrapperReplacement = styled.p`
    font-size: 14px;
    margin-bottom: 0;
    margin-top: 0;
    color: ${colors.grayDarker};
`;
