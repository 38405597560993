import { useEffect } from "react";
import { UseFormReturn } from "react-hook-form";

const TriggerFormInitially = ({
    trigger,
}: {
    trigger: UseFormReturn<any, unknown, any>["trigger"];
}) => {
    useEffect(() => {
        trigger();
    }, [trigger]);

    return null;
};

export default TriggerFormInitially;
