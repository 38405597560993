import React from "react";
import styled from "styled-components";
import extractValue from "../../common/helpers/extractValue";
import { breakpoint } from "../../common/variables";

const Grid = ({
    children,
    gutter
}: {
    children: React.ReactNode;
    gutter?: string;
}) => <Wrapper gutter={gutter}>{children}</Wrapper>;

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    margin: ${({ gutter }: { gutter?: string }) =>
        gutter ? `0 ${-(extractValue(gutter) / 2)}px` : 0};

    @media ${breakpoint.large} {
        flex-direction: row;
    }
`;

export default Grid;
