import React from "react";
import styled from "styled-components";
import assets from "../common/assets";
import colors from "../common/colors";
import mixins from "../common/mixins";
import { fontSize } from "../common/variables";

interface Props {
    onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
    text?: string;
    value: boolean;
    readOnly?: boolean;
    iconColor?: string;
}

const Checkbox = React.forwardRef<HTMLInputElement, Props>((props, ref) => {
    const { value, onChange, text, readOnly, iconColor } = props;

    return (
        <Wrapper>
            <Content readOnly={readOnly}>
                <Input
                    ref={ref}
                    type="checkbox"
                    checked={value}
                    onChange={onChange}
                    readOnly={readOnly}
                    disabled={readOnly}
                />
                <Marker>
                    <Icon checked={value} color={iconColor}>
                        {assets.checkmark}
                    </Icon>
                    <Dot checked={value} />
                </Marker>
                {text?.length && <Text>{text}</Text>}
            </Content>
        </Wrapper>
    );
});

export default Checkbox;

const Wrapper = styled.div`
    min-height: 16px;
    display: flex;
    align-items: center;
`;

const Content = styled.div<{ readOnly?: boolean }>`
    display: flex;
    align-items: center;
    position: relative;

    opacity: ${({ readOnly }) => (readOnly ? "0.5" : "1")};
`;

const Icon = styled.span<{ color?: string; checked?: boolean }>`
    height: 16px;
    width: 16px;
    position: absolute;
    top: 50%;
    transition: transform ${mixins.transitions.default};
    fill: ${({ color }) => (color ? color : colors.green)};

    transform: ${({ checked }) =>
        checked ? "translateY(-50%) scale(1)" : "translateY(-50%) scale(0)"};
`;

const Input = styled.input`
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    margin: 0;
    opacity: 0;
    z-index: 1;

    cursor: ${({ readOnly }) => (readOnly ? "default" : "pointer")};
`;

const Marker = styled.div`
    display: flex;
    width: 25px;
    height: 100%;
    position: relative;
`;

const Dot = styled.span`
    position: absolute;
    top: 50%;
    left: 0;
    height: 16px;
    width: 16px;
    border-radius: 8px;
    border: 1px solid ${colors.grayMid};
    transition: transform ${mixins.transitions.default};

    transform: ${({ checked }: { checked?: boolean }) =>
        checked ? "translateY(-50%) scale(0)" : "translateY(-50%) scale(1)"};
`;

const Text = styled.span`
    font-size: ${fontSize.small};
`;
