import * as TooltipPrimitive from "@radix-ui/react-tooltip";
import React, { ReactNode } from "react";
import styled from "styled-components";
import colors from "../common/colors";
import { space } from "../common/variables";

export function Tooltip({
    children,
    content,
    open,
    defaultOpen,
    onOpenChange,
    ...props
}: TooltipPrimitive.TooltipProps & { content: ReactNode }) {
    return (
        <TooltipPrimitive.Root
            open={open}
            defaultOpen={defaultOpen}
            onOpenChange={onOpenChange}>
            <TooltipPrimitive.Trigger>{children}</TooltipPrimitive.Trigger>
            <TTContent
                side="top"
                align="center"
                alignOffset={20}
                sideOffset={10}
                {...props}>
                {content}
                <TTArrow width={11} height={5} />
            </TTContent>
        </TooltipPrimitive.Root>
    );
}

const TTContent = styled(TooltipPrimitive.Content)`
    padding: ${space.xSmall};
    border-radius: ${space.xSmall};
    box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.15);
    background-color: ${colors.white};
`;

const TTArrow = styled(TooltipPrimitive.Arrow)`
    fill: ${colors.white};
`;

export const TooltipProvider = TooltipPrimitive.TooltipProvider;
