import React from "react";
import styled, { css } from "styled-components";
import colors from "../common/colors";
import mixins from "../common/mixins";
import { fontSize, space } from "../common/variables";
import Loader from "./Loader";

export interface ButtonProps {
    title?: string;
    outsideTitle?: string;
    icon?: React.ReactNode;
    size?: "small" | "large";
    theme?: "inverted" | "greenDark" | "red" | "redInverted";
    shape?: "round";
    disabled?: boolean;
    wrapperStyle?: React.CSSProperties;
    containerStyle?: React.CSSProperties;
    type?: "button" | "submit" | "reset";
}

interface Props extends ButtonProps {
    onClick?: () => void;
    onMouseEnter?: () => void;
    onMouseLeave?: () => void;
    preventDefault?: boolean;
    isLoading?: boolean;
}

const Button = (props: Props) => {
    const {
        title,
        icon,
        outsideTitle,
        isLoading,
        preventDefault,
        onClick,
        onMouseEnter,
        onMouseLeave,
    } = props;

    function onButtonClick(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
        if (props.type !== "submit" && preventDefault) {
            e.preventDefault();
        }

        onClick && onClick();
    }

    function onButtonMouseEnter(
        e: React.MouseEvent<HTMLButtonElement, MouseEvent>
    ) {
        if (preventDefault) {
            e.preventDefault();
        }

        onMouseEnter && onMouseEnter();
    }

    function onButtonMouseLeave(
        e: React.MouseEvent<HTMLButtonElement, MouseEvent>
    ) {
        if (preventDefault) {
            e.preventDefault();
        }

        onMouseLeave && onMouseLeave();
    }

    return (
        <Wrapper
            {...props}
            type={props.type || "button"}
            style={props.wrapperStyle}
            onClick={onButtonClick}
            onMouseEnter={onButtonMouseEnter}
            onMouseLeave={onButtonMouseLeave}>
            <Container
                style={props.containerStyle}
                disabled={props.disabled}
                theme={props.theme}>
                <Content
                    size={props.size}
                    shape={props.shape}
                    hasIconOnly={!!icon && !outsideTitle}>
                    <LoadingContainer isLoading={isLoading}>
                        <Loader small noMinHeight color={colors.white} />
                    </LoadingContainer>
                    {title && <Text isLoading={isLoading}>{title}</Text>}
                    {icon && <Icon>{icon}</Icon>}
                </Content>

                {outsideTitle && (
                    <Text style={{ marginLeft: space.xSmall }}>
                        {outsideTitle}
                    </Text>
                )}
            </Container>
        </Wrapper>
    );
};

export default Button;

const Wrapper = styled.button`
    display: flex;
    overflow: hidden;
`;

const Container = styled.div<{
    disabled?: boolean;
    theme?: "inverted" | "greenDark" | "red" | "redInverted";
}>`
    display: flex;
    align-items: center;
    cursor: pointer;
    transition: opacity ${mixins.transitions.default};

    svg {
        fill: currentColor;
    }

    ${({
        theme,
    }: {
        theme?: "inverted" | "greenDark" | "red" | "redInverted";
    }) =>
        theme === "inverted"
            ? css`
                  ${Content} {
                      background-color: white;
                      border: 1px solid ${colors.border};
                      color: ${colors.green};
                      fill: ${colors.green};
                  }

                  &:hover {
                      ${Content} {
                          border-color: ${colors.green};
                      }
                  }
              `
            : theme === "greenDark"
            ? css`
                  ${Content} {
                      background-color: ${colors.greenDark};
                      border: 1px solid ${colors.greenDark};
                      color: ${colors.white};
                      fill: ${colors.white};
                  }
                  &:hover {
                      ${Content} {
                          opacity: 0.9;
                      }
                  }
              `
            : theme === "redInverted"
            ? css`
                  ${Content} {
                      background-color: white;
                      border: 1px solid ${colors.red};
                      color: ${colors.red};
                      fill: ${colors.red};
                  }
                  &:hover {
                      ${Content} {
                          background-color: ${colors.red};
                          color: ${colors.white};
                          fill: ${colors.white};
                      }
                  }
              `
            : theme === "red"
            ? css`
                  ${Content} {
                      background-color: ${colors.red};
                      border: 1px solid ${colors.red};
                      color: ${colors.white};
                      fill: ${colors.white};
                  }
                  &:hover {
                      ${Content} {
                          opacity: 0.9;
                      }
                  }
              `
            : css`
                  ${Content} {
                      background-color: ${colors.green};
                      color: ${colors.white};
                      fill: ${colors.white};
                  }
                  &:hover {
                      ${Content} {
                          background-color: ${colors.greenDark};
                      }
                  }
              `};

    ${({ disabled }: { disabled?: boolean }) =>
        disabled &&
        css`
            pointer-events: none;
            opacity: 0.5;
        `}
`;

const Content = styled.span<{
    size?: "small" | "large";
    shape?: "round";
    hasIconOnly?: boolean;
}>`
    display: flex;
    align-items: center;
    flex-shrink: 0;
    flex-grow: 0;
    justify-content: center;
    position: relative;

    ${({ size }) =>
        size === "small"
            ? css`
                  height: 30px;
                  border-radius: 15px;
                  padding: 0 ${space.medium};
              `
            : size === "large"
            ? css`
                  height: 60px;
                  border-radius: 30px;
                  padding: 0 ${space.xLarge};
              `
            : css`
                  height: 40px;
                  border-radius: 20px;
                  padding: 0 ${space.xLarge};
              `};

    ${({ shape, size }) =>
        shape === "round" && size === "small"
            ? css`
                  padding: 8px 11px 11px 11px;
                  width: 30px;
              `
            : shape === "round" && size === "large"
            ? css`
                  padding: 11px;
                  width: 60px;
              `
            : shape === "round"
            ? css`
                  padding: 9px 11px 11px 11px;
                  width: 40px;
              `
            : css`
                  width: 100%};
              `};

    ${({ hasIconOnly }) =>
        hasIconOnly &&
        css`
            padding: 0px;
            display: flex;
            justify-content: center;
            align-items: center;
        `};
`;

const LoadingContainer = styled.span`
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 1;
    transition: opacity ${mixins.transitions.default};

    ${({ isLoading }: { isLoading?: boolean }) =>
        isLoading
            ? css`
                  display: inline;
              `
            : css`
                  display: none;
              `};
`;

const Text = styled.span`
    font-size: ${fontSize.small};
    line-height: ${fontSize.small};
    transition: opacity ${mixins.transitions.default};

    ${({ isLoading }: { isLoading?: boolean }) =>
        isLoading
            ? css`
                  opacity: 0;
              `
            : css`
                  opacity: 1;
              `};
`;

const Icon = styled.div`
    svg {
        display: block;
    }
`;
