import { Table as TanstackTable, flexRender } from "@tanstack/react-table";
import React from "react";
import styled, { css } from "styled-components";
import assets from "../../common/assets";
import { breakpoint, space } from "../../common/variables";
import { TableHeaderStyles } from "./TableHeader";
import { TableRowStyles } from "./TableRow";

interface Table2Props<T> {
    table: TanstackTable<T>;
    rowGap?: keyof typeof space;
    modifiedTheme?: boolean;
    getLinkTo?: (item: T) => string;
}

const Table = <T,>(props: Table2Props<T>) => {
    return (
        <TableWrapper>
            {props.table.getHeaderGroups().map((headerGroup) => (
                <TableHeaderStyles.Wrapper key={headerGroup.id}>
                    {headerGroup.headers.map((header) => {
                        const meta =
                            header.column.columnDef.meta?.getCellContext(
                                header.getContext()
                            );

                        return (
                            <TableHeaderStyles.Cell
                                key={header.id}
                                headerLength={meta?.length}
                                fixedWidth={meta?.fixedWidth}
                                growWidth={meta?.growWidth}>
                                {header.column.getCanSort() ? (
                                    <TableHeaderStyles.TitleClickable
                                        onClick={header.column.getToggleSortingHandler()}>
                                        {flexRender(
                                            header.column.columnDef.header,
                                            header.getContext()
                                        )}
                                        <TableHeaderStyles.Icon
                                            visible={
                                                !!header.column.getIsSorted()
                                            }>
                                            <TableHeaderStyles.IconContainer
                                                sorting={
                                                    header.column.getIsSorted() ||
                                                    undefined
                                                }>
                                                {assets.angle}
                                            </TableHeaderStyles.IconContainer>
                                        </TableHeaderStyles.Icon>
                                    </TableHeaderStyles.TitleClickable>
                                ) : (
                                    <TableHeaderStyles.Title>
                                        {flexRender(
                                            header.column.columnDef.header,
                                            header.getContext()
                                        )}
                                    </TableHeaderStyles.Title>
                                )}
                            </TableHeaderStyles.Cell>
                        );
                    })}
                </TableHeaderStyles.Wrapper>
            ))}
            <RowWrapper rowGap={props.rowGap}>
                {props.table.getRowModel().rows.map((row) => (
                    <TableRowStyles.Wrapper
                        key={row.id}
                        noLink={!props.getLinkTo}
                        to={props.getLinkTo?.(row.original)}>
                        <TableRowStyles.Content
                            modifiedTheme={props.modifiedTheme}>
                            {row.getVisibleCells().map((cell) => {
                                const meta =
                                    cell.column.columnDef.meta?.getCellContext(
                                        cell.getContext()
                                    );

                                return (
                                    <TableRowStyles.Cell
                                        key={cell.id}
                                        rowLength={meta?.length}
                                        fixedWidth={meta?.fixedWidth}
                                        growWidth={meta?.growWidth}>
                                        <TableRowStyles.Title>
                                            {flexRender(
                                                cell.column.columnDef.cell,
                                                cell.getContext()
                                            )}
                                        </TableRowStyles.Title>
                                    </TableRowStyles.Cell>
                                );
                            })}
                        </TableRowStyles.Content>
                    </TableRowStyles.Wrapper>
                ))}
            </RowWrapper>
        </TableWrapper>
    );
};

export default Table;

const TableWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;

    --tableColumnGap: ${space.xSmall};

    @media ${breakpoint[600]} {
        --tableColumnGap: ${space.small};
    }

    @media ${breakpoint[900]} {
        --tableColumnGap: ${space.large};
    }
`;

const RowWrapper = styled.div<{ rowGap?: keyof typeof space }>`
    display: flex;
    flex-direction: column;

    ${({ rowGap }) =>
        !!rowGap &&
        css`
            --tableRowGap: ${space[rowGap]};
            gap: var(--tableRowGap);
        `}}
`;

const PaginationWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: ${space.large};
    gap: ${space.medium};
`;

const PaginationContent = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`;

const colorGrayLight3 = "#d7d7d7";

const PaginationTrigger = styled.button`
    height: 40px;
    width: 40px;
    border-radius: 50%;
    margin: 0 8px;
    border: 1px solid ${colorGrayLight3};
    display: flex;
    flex-shrink: 0;
    align-items: center;
    justify-content: center;
    background-color: transparent;

    &:first-of-type {
        margin-left: 0;
    }

    &:last-of-type {
        margin-right: 0;
    }

    &:hover {
        cursor: pointer;
    }

    color: #333;
    font-weight: bold;
    height: 40px;
    width: 40px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;

    &:disabled {
        visibility: hidden;
    }

    svg {
        height: 12px;
        width: 12px;
        fill: #777;
    }
`;

const PaginationRightTrigger = styled(PaginationTrigger)`
    margin-left: ${space.xSmall};
`;

const PaginationLeftTrigger = styled(PaginationTrigger)`
    margin-right: ${space.xSmall};
`;

export const PaginationStyles = {
    Wrapper: PaginationWrapper,
    Content: PaginationContent,
    LeftTrigger: PaginationLeftTrigger,
    RightTrigger: PaginationRightTrigger,
};
