import styled, { css } from "styled-components";
import extractValue from "../common/helpers/extractValue";
import { space } from "../common/variables";

export const FormFieldSet = styled.div<{ center?: boolean }>`
    display: flex;
    margin: 0 -${extractValue(space.large) / 2}px;
    ${({ center }) =>
        center &&
        css`
            align-items: center;
        `};
`;

export const FormField = styled.div<{
    halfSize?: boolean;
    threeQuarterSize?: boolean;
    noMargin?: boolean;
}>`
    margin-bottom: ${({ noMargin }) => (noMargin ? 0 : space.large)};

    ${({ halfSize }) =>
        halfSize &&
        css`
            width: 50%;
            padding: 0 ${extractValue(space.large) / 2}px;
        `};

    ${({ threeQuarterSize }) =>
        threeQuarterSize &&
        css`
            width: 75%;
            padding: 0 ${extractValue(space.large) / 2}px;
        `};
`;

export const FormFieldGroup = styled.div`
    margin-left: ${space.large};
`;
