import React from "react";
import FormInvalidError from "../../components/FormInvalidError";
import {
    CellBlockProperty,
    CellBlockSection,
    CellBlockTitle,
    CellBlockValue,
} from "../../components/Grid/CellBlock";
import { useUnitInfoForm } from "./UnitView.hooks";

interface UnitInfoListingProps extends ReturnType<typeof useUnitInfoForm> {}

const UnitInfoListing = (props: UnitInfoListingProps) => {
    const { getValues, formState } = props;

    const { name, line1, postalCode, town, phone, doorCode, stairs } =
        getValues();

    return (
        <>
            <CellBlockSection>
                <CellBlockTitle>{name}</CellBlockTitle>
                <CellBlockValue>{line1}</CellBlockValue>
                <CellBlockValue>{`${postalCode} ${town}`}</CellBlockValue>
            </CellBlockSection>
            <CellBlockSection>
                {phone?.length ? (
                    <CellBlockSection>
                        <CellBlockProperty>Telefon</CellBlockProperty>
                        <CellBlockValue>{phone}</CellBlockValue>
                    </CellBlockSection>
                ) : null}
                {doorCode?.length ? (
                    <CellBlockSection>
                        <CellBlockProperty>Portkod</CellBlockProperty>
                        <CellBlockValue>{doorCode}</CellBlockValue>
                    </CellBlockSection>
                ) : null}
                {stairs?.length ? (
                    <CellBlockSection>
                        <CellBlockProperty>Trappor</CellBlockProperty>
                        <CellBlockValue>{`${stairs}st`}</CellBlockValue>
                    </CellBlockSection>
                ) : null}
            </CellBlockSection>
            {!formState.isValid && (
                <CellBlockSection>
                    <FormInvalidError />
                </CellBlockSection>
            )}
        </>
    );
};

export default UnitInfoListing;
