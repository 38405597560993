import { days, months } from "../translations";

export const isExpired = (cardDate: string) => {
    /* cardDate format from hybris is: 05/2020 */
    const date = new Date();
    const year = date.getFullYear();
    const month = date.getMonth() + 1;

    const [cardMonth, cardYear] = cardDate.split("/").map(x => +x);
    return year >= cardYear && month > cardMonth; 
}

// IE11 and iOS is unable to parse the date format so date string need to be modified before parsing.
export const safelyParseISO8601extendedFormat = (d: string) => {
    return d.replace("+0000", "");
}

// Converts format from 2020-07-14T14:00:00+0000 to Fredag, 12 Juli
export const toFriendlyDateString = (timestamp: string) => {
    if(!timestamp) {
        return ""
    }
    return `${
        days[
            new Date(safelyParseISO8601extendedFormat(timestamp)).getDay()
        ]
    }, ${new Date(
        safelyParseISO8601extendedFormat(timestamp)
    ).getDate()} ${
        months[
            new Date(
                safelyParseISO8601extendedFormat(timestamp)
            ).getMonth()
        ]
    }`;
}