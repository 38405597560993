import React, { useRef } from "react";
import { animated } from "react-spring";
import styled from "styled-components";
import colors from "../common/colors";
import mixins from "../common/mixins";
import { fontSize, space } from "../common/variables";
import { useAnimatedDropdownState } from "../hooks/useAnimatedDropdownState";

interface Props {
    headerContent: React.ReactNode;
    children: React.ReactNode;
}

const DropdownMenu = ({ children, headerContent }: Props) => {
    const ref = useRef<HTMLDivElement & any>();
    const { isExpanded, animProps, toggle } = useAnimatedDropdownState(ref);

    return (
        <Wrapper ref={ref}>
            <Header onClick={toggle}>{headerContent}</Header>

            <Panel expanded={isExpanded ? 1 : 0} style={animProps}>
                {children}
            </Panel>
        </Wrapper>
    );
};

export default DropdownMenu;

const Wrapper = styled.div`
    position: relative;
    z-index: 1;
`;

const Header = styled.button`
    display: flex;
    align-items: center;
    position: relative;
    border: none;
    font-size: ${fontSize.small};
    outline: none;
    text-align: left;
    cursor: pointer;
`;

const Panel = styled(animated.div)<{ expanded?: number }>`
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    top: ${space.xxLarge};
    right: 0;
    width: 100%;
    min-width: 150px;
    padding: ${space.medium};
    background-color: ${colors.white};
    border-radius: ${mixins.borderRadius.default};
    box-shadow: ${mixins.boxShadow.bottomAngle};
    border: 1px solid ${colors.border};
    pointer-events: ${({ expanded }: { expanded?: number }) =>
        expanded ? "auto" : "none"};

    &:before,
    &:after {
        content: "";
        position: absolute;
        right: ${space.medium};
        width: 0;
        height: 0;
    }

    &:before {
        top: -10px;
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        border-bottom: 10px solid ${colors.border};
    }

    &:after {
        top: -8px;
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        border-bottom: 10px solid ${colors.white};
    }
`.withComponent(animated.div);
