interface Colors {
    /**
     * #ffffff
     */
    white: string;

    /**
     * #333333
     */
    black: string;

    /**
     * #ededed
     */
    border: string;

    /**
     * #f5f5f5
     */
    grayLighter: string;

    /**
     * #ededed
     */
    grayLight: string;

    /**
     * #d7d7d7
     */
    grayMid: string;

    /**
     * #999999
     */
    grayDark: string;

    /**
     * #777777
     */
    grayDarker: string;

    /**
     * #e8f2d2
     */
    greenLight: string;

    /**
     * #00aa46
     */
    green: string;

    /**
     * #005537
     */
    greenDark: string;

    /**
     * #c74423
     */
    red: string;

    /**
     * #CC0000
     */
    redDark: string;

    /**
     * #ffeaf3
     */
    pink: string;

    /**
     * #fea5cc
     */
    pinkDark: string;
}

const colors: Colors = {
    white: "#ffffff",
    black: "#333333",
    border: "#ededed",
    grayLighter: "#f5f5f5",
    grayLight: "#ededed",
    grayMid: "#d7d7d7",
    grayDark: "#999999",
    grayDarker: "#777777",
    greenLight: "#e8f2d2",
    green: "#00aa46",
    greenDark: "#005537",
    red: "#c74423",
    redDark: "#CC0000",
    pink: "#ffeaf3",
    pinkDark: "#fea5cc",
};

export default colors;
