import React from "react";
import styled from "styled-components";
import { fontSize, space } from "../common/variables";
import colors from "../common/colors";

interface Props {
    heading?: string
    text?: string
}

const EmptyState = (props: Props) =>
    <Wrapper>
        {props.heading && <Heading>{props.heading}</Heading>}
        {props.text && <Text>{props.text}</Text>}
    </Wrapper>

export default EmptyState

const Wrapper = styled.main`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    min-height: 200px;
`;

const Heading = styled.p`
    color: ${colors.black};
    font-size: ${fontSize.medium};
    margin: 0;
    margin-bottom: ${space.small};
`;

const Text = styled.p`
    color: ${colors.grayDarker};
    font-size: ${fontSize.small};
    margin: 0;
`;