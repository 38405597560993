import React, { PropsWithChildren, useContext } from "react";
import { Navigate } from "react-router-dom";
import { UserContext } from "../contexts/UserContext";
import { navigationItems } from "../views/navigationItems";
import Loader from "./Loader";

const AdminAuthorized = (props: PropsWithChildren<{}>) => {
    const { authUserData } = useContext(UserContext);

    const isAuthorized =
        authUserData && authUserData.municipalityRole === "ADMIN";

    if (isAuthorized === true) {
        return <>{props.children}</>;
    }

    if (isAuthorized === false) {
        return <Navigate to={navigationItems[0].path} replace />;
    }

    return <Loader fullHeight />;
};

export default AdminAuthorized;
