import React, { useMemo } from "react";
import AuthService from "./authService";
import { User } from "oidc-client";

export interface AuthContextType {
    signinRedirectCallback: () => Promise<User | null>;
    signinRedirect: () => Promise<void>;

    getUser: () => Promise<User | null>;
    signout: () => Promise<[void, void]>;
}

export const AuthContext = React.createContext<any>({
    signinRedirectCallback: () => Promise.resolve(null),
    signinRedirect: () => Promise.resolve(undefined),

    getUser: () => Promise.resolve(null),
    signout: () => Promise.resolve([undefined, undefined]),
});

export const AuthConsumer = AuthContext.Consumer;

const AuthProvider = ({ children }: { children: React.ReactNode }) => {
    const authService = useMemo(() => new AuthService(), []);

    return (
        <AuthContext.Provider value={authService}>
            {children}
        </AuthContext.Provider>
    );
};

export default AuthProvider;
