import { ORDERER_DUPLICATE_EMAIL_MESSAGE } from "../../common/exceptions";
import {
    HybrisFieldError,
    HybrisFieldValidationResponse,
    ListOption,
} from "../../common/types";
import { TableRowItem } from "../../components/Table/tableHelpers";
import { OrdererInfoData } from "./OrdererInfoForm";
import {
    Orderer,
    OrdererInput,
    OrdererListingItem,
    OrdererRowDataType,
} from "./ordererTypes";

export const ordererRowData = (data: OrdererListingItem[]) =>
    data.map<TableRowItem<OrdererRowDataType>>((orderer) => ({
        data: {
            ...orderer,
            id: orderer.orderer.uid,
        },
        rowValues: [
            {
                sortPredicate: `${orderer.orderer.firstName} ${orderer.orderer.lastName}`,
                value: `${orderer.orderer.firstName} ${orderer.orderer.lastName}`,
            },
            {
                sortPredicate: `${orderer.orderer.municipalEmail}`,
                value: `${orderer.orderer.municipalEmail}`,
            },
            {
                sortPredicate: orderer.authUnits?.length
                    ? `${orderer.authUnits[0].name}`
                    : "",
                value: orderer.authUnits?.length
                    ? `${orderer.authUnits
                          ?.filter((o, i) => i < 3)
                          .map((o) => o.name)
                          .join(", ")}`
                    : "Inga enheter",
            },
        ],
    }));

export const convertOrdererFromOutput = (data?: Orderer) =>
    ({
        firstName: data?.firstName || "",
        lastName: data?.lastName || "",
        phone: data?.municipalPhoneNumber || "",
        email: data?.municipalEmail || "",
        customerCreationAllowed: data?.customerCreationAllowed || false,
    } as OrdererInfoData);

export const convertOrdererForInput = (
    formData: OrdererInfoData,
    units: ListOption[]
) =>
    ({
        firstName: formData?.firstName || "",
        lastName: formData.lastName || "",
        municipalityPhone: formData?.phone || "",
        email: formData?.email || "",
        customerCreationAllowed: formData.customerCreationAllowed,
        unitIds: units.map((unit) => unit.id),
    } as OrdererInput);

/**
 * Hybris returns erros with different models
 * This function is to map known exceptions to the form field names
 */
export const mapHybrisOrdererErrorField = (
    args: HybrisFieldError
): { fieldName: string; message: string } => {
    if (args.message === ORDERER_DUPLICATE_EMAIL_MESSAGE) {
        return {
            fieldName: "email",
            message: "E-postadressen finns redan registrerad",
        };
    }

    return { fieldName: args.subject, message: "" };
};

export const getModalOrdererErrorText = (
    response: HybrisFieldValidationResponse
) => {
    if (
        response.errors.find(
            (error) => error.message === ORDERER_DUPLICATE_EMAIL_MESSAGE
        )
    ) {
        return "En användare med den e-postadressen du uppgav existerar redan.";
    }

    return undefined;
};
